import React, { createContext, useState } from 'react';

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {

  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const addNotification = (notification) => {
    setNotifications(prevNotifications => [...prevNotifications, notification]);
    setNotificationCount(prevCount => prevCount + 1);

    // try {
    //   // Check if the notification is a string and parse it if needed
    //   const parsedNotification =
    //     typeof notification === "string" ? JSON.parse(notification) : notification;

    //     alert(parsedNotification);
  
    //   setNotifications((prevNotifications) => [
    //     ...prevNotifications,
    //     parsedNotification,
    //   ]);
    //   setNotificationCount((prevCount) => prevCount + 1);
    // } catch (error) {
    //   console.error("Failed to parse notification:", error.message);
    // }
  };

  const clearNotifications = () => {
    setNotifications([]);
    setNotificationCount(0);
  };

  const removeNotification = (index) => {
    setNotifications(prevNotifications => {
      const newNotifications = [...prevNotifications];
      newNotifications.splice(index, 1);
      return newNotifications;
    });
    setNotificationCount(prevCount => prevCount - 1);
  };

  return (
    <NotificationContext.Provider value={{ notifications, notificationCount, addNotification, clearNotifications,removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };

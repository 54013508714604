import React, { useState, useRef,useContext } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { json, useNavigate } from "react-router-dom";
import { NotificationContext } from "../../../components/NotificationContext";
import IconButton from "@mui/material/IconButton";
import "react-medium-image-zoom/dist/styles.css";
import FilterIcon from "@mui/icons-material/Filter";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";



function NotificationPage() {
  const navigate = useNavigate();

  const {

    notifications,
   removeNotification
  } = useContext(NotificationContext);

  

  const swipeNSelection = (event) => {
    forSelectList(event);
    resetSwipeDirection(event);
  };

  const forSelectList = () => {
    var element = document.getElementById("listID");
    element.classList.toggle("selectedList");
  };

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        setSwipeDirection("right");
      } else {
        setSwipeDirection("left");
      }
    }
  };


  const navigateToDetail = () => {
    const userType=localStorage.getItem('userType');
    if(userType=='Admin')
    {
        navigate('/SchedulPreview')
    }
    else
    {
      navigate('//ShiftSwapAndOffer')
    }
  };

  const handleNotificationClick = (index) => {
    removeNotification(index);
  };


  const handleTouchEnd = () => {
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  return (
    
    <>
      <div className="wrapper notificationPageContMain innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Notifications</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <h1 className="dayOfNotification">Today</h1>
          <div className="employyeListingMain">
   
          {notifications.length > 0 &&
                notifications.map((item, index) => {
                   // Parse or ensure notification is an object
        // const item =
        // typeof notification === "string"
        //   ? JSON.parse(notification)
        //   : notification;
            return (
            <div
              onClick={()=>navigateToDetail()}
              key={index}
              className={`swipe-container ${
                swipeDirection === "left" ? "swiped-left" : ""
              } ${swipeDirection === "right" ? "swiped-right" : ""}`}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div className="deleEdiBtLeft" onClick={handleNotificationClick(index)}>
                <IconButton>
                  <DeleteIcon className="DeleteIcon"/>
                </IconButton>
              </div>
              <div
                className="emploTaskListInr"
                id="listID"
                onClick={swipeNSelection}
              >
                <em></em>
               
                <div>{item?.body}</div>
               
              </div>
              <div className="deleEdiBtRight" onClick={handleNotificationClick(index)}>
                <IconButton>
                  <DeleteIcon className="DeleteIcon" />
                </IconButton>
              </div>
            </div>
                )})}
          
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationPage;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import moment from "moment/moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import swal from "sweetalert";
import avtarPic from "../../../assets/images/Profile-Picture.jpg";
import avtarP from "../../../assets/images/avtarPic.png";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ENd

function getDatesArray(startDate, endDate) {
  const dates = [];
  const start = moment(startDate);
  const end = moment(endDate);

  while (start.isSameOrBefore(end)) {
    dates.push({
      date: start.format("YYYY-MM-DD"),
      day: start.format("ddd"),
      formatdate: start.format("DD"),
    });
    start.add(1, "day");
  }

  return dates;
}

function SharedWith() {
  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const [transcript, setTranscript] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [showInput, setShowInput] = useState(false);

  function myFunction() {
    var element = document.getElementById("searchId");
    element.classList.toggle("addedSearch");
  }

  function openMick() {
    var element = document.getElementById("openMickId");
    element.classList.toggle("openMickDiv");
  }

  useEffect(() => {
    let recognition;

    if (isListening) {
      recognition = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        const currentTranscript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        setTranscript(currentTranscript);
      };

      recognition.start();

      recognition.onend = () => {
        setIsListening(false);
      };
    }

    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, [isListening]);

  const handleMicClick = () => {
    setShowInput(true);
    setIsListening(true);
  };

  const handleClose2 = () => {
    setShowInput(false);
    setTranscript("");
  };

  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(false);
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [sortBy, setSortBy] = React.useState("asc");
  const [upload, setUpload] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [categoryid, setCategoryID] = React.useState([]);
  const [categoryname, setCategoryName] = React.useState([]);
  const [frequancyid, setFrequancyID] = React.useState([]);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [slider1, setSlider1] = useState(null);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [tempSlide, setTempSlide] = React.useState("");
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment("2024-10-01"));
  const [startADate, setStartADate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("year").add(1, "year")
  );
  const [datesArray, setDatesArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [UserId, setUserId] = useState();
  const [weekDay, setWeekDay] = useState(moment().format("dddd"));
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [completeIteration, setCompleteIteration] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(10);
  const [avaialableEmployee, setAvaialableEmployee] = useState([]);
  const [availablecnt, setAvailableCnt] = useState(0);

  const initialValues = [100];
  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
  }

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (taskObj) => {
    console.log(taskObj);
    if (taskObj.assigntask == null) {
      swal("This Task is not Assigned to anyone ", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    } else {
      if (
        (taskObj.assigntask != null &&
          taskObj.assigntask[0].before_img !== null) ||
        taskObj.assigntask[0].after_img !== null
      ) {
        setImageUploadBefore(taskObj.assigntask[0].before_img);
        setImageUploadAfter(taskObj.assigntask[0].after_img);
        setOpen(true);
      } else {
        swal("No Image Uploaded", "Information", "info", {
          timer: 3000,
          buttons: false,
        });
        setOpen(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  function getArrowDatesArray(startDate, endDate) {
    const dates = [];
    const start = moment(startDate);
    const end = moment(endDate);

    while (start.isSameOrBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "day");
    }

    //console.log("new dates===>"+JSON.stringify(dates))

    return dates;
  }

  const datesArrayVal = getDatesArray(startDate, endDate);
  const today = moment();
  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(today, "day")
  );

  const settings = {
    initialSlide: todayIndex,
    // className: "center",
    // centerMode: false,
    // infinite: true,
    // centerPadding: "0px",
    // slidesToShow: 3,
    // speed: 500,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,

    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    //autoplay: true,
    // nextArrow: <NextArrow />,
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  function getSelectedWeek(startDate) {
    let week = [];
    var newdates = new Date(startDate);
    var d = new Date(startDate);
    d.setDate(1);
    var curr = getMonday(newdates);
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    setCurrentWeekCal(week);
  }

  function SelectSliders(idx, obs) {
    setStartADate(obs);
    const date = moment(obs);
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    slider1?.slickGoTo(idx);
    ChangeDateFun(categoryid, obs, dayName);
  }

  const changeList = (e, id, catname) => {
    setShowLoader(true);
    setCategoryID(id);
    setCategoryName(catname);
    const obsdate = moment(todaysDate.time).format("YYYY-MM-DD");
    setStartADate(moment(todaysDate.time).format("YYYY-MM-DD"));
    const date = moment(moment(todaysDate.time).format("YYYY-MM-DD"));
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    ChangeDateFun(id, obsdate, dayName);
  };

  const getSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function getCompletedTaskCount(catid, startdate) {
    await makeGetRequest(
      "employeeschedular/selectreporttask/" + catid + "/" + startdate
    )
      .then((response) => {
        console.log("My available employee list==>" + JSON.stringify(response));
        if (response.data.data.rows) {
          if (response.data.data.rows.length > 0) {
            setAvaialableEmployee(response.data.data.rows);
            setAvailableCnt(response.data.data.rowCount);
            setShowLoader(false);
          } else {
            setAvaialableEmployee([]);
            setAvailableCnt(0);
            setShowLoader(false);
          }

          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const statusChangeFun = async (catid) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          catid +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, startADate);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
      setShowLoader(false);
    }
  };

  const ChangeDateFun = async (catid, searchdate, searchday) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          searchdate +
          "'/" +
          catid +
          "/'" +
          searchday +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar", response.data.data.res.rows[0].assigntask[0].after_img)
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].before_img)

              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, searchdate);
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);

            setCategoryID(response.data.data.res[0].categoryid);
            setCategoryName(response.data.data.res[0].category_name);
            setFrequancyID(response.data.data.res[0].frequancy_ids);

            const datesArray = getDatesArray(startDate, endDate);
            setMondaysCal(datesArray);
            statusChangeFun(response.data.data.res[0].categoryid);
            //setShowLoader(false);
          } else {
            setCategoryList([]);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    if (categoryid == null || categoryid == "" || categoryid.length == 0) {
      getAllCategoryList(null);
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");
    }
  }, []);
  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div
            className="personalTsProfileTpOut"
            onClick={() => navigate("/Profile")}
          >
            <div className="personalTsProfileTp">
              <img src={avtarPic} />
            </div>
            <h2>John Doe</h2>
          </div>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="sharedWithScreenMain">
          <div
            className={`recentSharedView ${
              scrolling ? "scrolledRecentShared" : ""
            }`}
          >
            <label className="sharedLabl">Recently Shared With</label>
            <div className="recentSharedHoriScroll">
              <div class="personalTsProfileTp">
                <img src={avtarP} />
              </div>
              <div class="personalTsProfileTp">
                <img src={avtarPic} />
              </div>
              <div class="personalTsProfileTp">
                <img src={avtarP} />
              </div>
              <div class="personalTsProfileTp">
                <img src={avtarPic} />
              </div>
            </div>
          </div>

          <div className="contactListMain">
            <label className="sharedLabl">Your Contacts</label>
            <div className="contactListInr">
              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Jane Cooper
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Cody Fisher
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Esther Howard
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Annette Black
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Guy Hawkins
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Savannah Nguyen
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Darrell Steward
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Floyd Miles
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Robert Fox
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Jane Cooper
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Cody Fisher
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Esther Howard
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Annette Black
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Guy Hawkins
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Savannah Nguyen
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Darrell Steward
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Floyd Miles
                </p>
              </div>

              <div className="speekAdded">
                <p>
                  <Checkbox {...label} defaultChecked />{" "}
                  <div class="personalTsProfileTp">
                    <img src={avtarP} />
                  </div>{" "}
                  Robert Fox
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SharedWith;

import React, { useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend"; // Mobile compatibility
import { Tabs, Tab, Box } from "@mui/material";
import { useSpring, animated } from "react-spring";

const ItemType = "TAB";

const DraggableTab = ({ tab, index, moveTab, isTargetHovered }) => {
  // Drag hook to make the tab draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop hook to detect where the tab is being dragged over
  const [, dropRef] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index) {
        moveTab(item.index, index); // Update tab order
        item.index = index;
      }
    },
  });

  // Spring animation for scaling and highlighting
  const springStyle = useSpring({
    transform: isDragging ? 1.1 : 1, // Scale up while dragging
    opacity: isDragging ? 0.6 : 1, // Fade out when dragging
    boxShadow: isDragging ? "0px 5px 10px rgba(0, 0, 0, 0.2)" : "none",
    backgroundColor: isTargetHovered ? "#d3d3d3" : "transparent", // Change background color if hovering over target area
    config: { tension: 300, friction: 15 },
    to: {
      transform: isDragging ? `scale(1.1)` : `scale(1)`, // Use "to" to animate the transform property as a string
    },
  });

  return (
    // Apply the animated styles directly to the tab
    <animated.div
      ref={(node) => dragRef(dropRef(node))}
      style={{
        ...springStyle,
        cursor: "move",
        padding: "5px 10px",
        borderRadius: "4px",
        transition: "background-color 0.3s ease", // Smooth transition for background color
      }}
    >
      <Tab label={tab} sx={{ pointerEvents: isDragging ? "none" : "auto" }} />
    </animated.div>
  );
};

const SortableTabs = () => {
  const [tabs, setTabs] = useState(["Tab 1", "Tab 2", "Tab 3", "Tab 4"]);
  const [value, setValue] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null); // Track index of hovered tab

  // Function to move tab
  const moveTab = (fromIndex, toIndex) => {
    const updatedTabs = [...tabs];
    const [movedTab] = updatedTabs.splice(fromIndex, 1);
    updatedTabs.splice(toIndex, 0, movedTab);
    setTabs(updatedTabs);
  };

  // Handle tab selection
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // React-Spring for smooth transition of the tabs during reordering
  const animatedTabs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
    reverse: false,
    config: { tension: 200, friction: 20 },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="sortable tabs">
        {tabs.map((tab, index) => (
          <DraggableTab
            key={index}
            tab={tab}
            index={index}
            moveTab={moveTab}
            isTargetHovered={hoverIndex === index} // Pass hover state to highlight the tab
          />
        ))}
      </Tabs>
      <Box sx={{ p: 3 }}>
        <div>Content for {tabs[value]}</div>
      </Box>
    </Box>
  );
};

const DragNDropTabsDemo = () => (
  <DndProvider backend={TouchBackend}>
    <SortableTabs />
  </DndProvider>
);

export default DragNDropTabsDemo;

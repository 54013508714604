import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useLocation, useNavigate } from "react-router-dom";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";

function UserBottom() {
  const navigate = useNavigate();
  const location = useLocation();
  const userType = localStorage.getItem("userType");
  const [value, setValue] = React.useState("recents");
  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };
  const adminFunc = () => {
    navigate("/ReportAdmin");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/EmployeeTaskListNew");
        break;
      case 1:
        navigate("/MyAvailability");
        break;
      /* case 2:
        navigate("/PersonalTasks");
        break; */
      case 4:
        navigate("/ShiftSwapAndOffer");
        break;
    }
  };

  React.useEffect(() => {
    switch (location.pathname) {
      case "/EmployeeTaskListNew":
        setValue(0);
        break;
      case "/MyAvailability":
        setValue(1);
        break;
      /* case "/PersonalTasks":
        setValue(2);
        break; */
      case "/ShiftSwapAndOffer":
        setValue(4);
        break;
    }
  }, []);
  return (
    <>
      <div className="adminBottomNavMain">
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction
            label=""
            value={0}
            icon={<FormatListBulletedIcon />}
            //onClick={() => navigate("/ReportAdmin")}
          />
          <BottomNavigationAction
            label=""
            value={1}
            icon={<EventAvailableIcon />}
            //onClick={() => navigate("/EmployeeListing")}
          />
          {/* <BottomNavigationAction
            label=""
            value={2}
            icon={<PlaylistAddIcon />}
            //onClick={() => navigate("/EmployeeListing")}
          /> */}
          {/* <BottomNavigationAction
            onClick={userType == "Admin"? adminFunc : logoutFunction}
            label=""
            value={3}
            icon={userType == "Admin"? <AdminPanelSettingsIcon/> : <PowerSettingsNewIcon />}
            //onClick={() => navigate("/AdminList")}
          /> */}
          <BottomNavigationAction
            label=""
            value={4}
            icon={<AlarmOnIcon />}
            //onClick={() => navigate("/EmployeeListing")}
          />
        </BottomNavigation>
      </div>
      <div className="adminBottomNavPatch"></div>
    </>
  );
}

export default UserBottom;

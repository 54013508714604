import React, {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import editIco from "../../../../assets/images/edit-icon.svg";
import companyLogo from "../../../../assets/images/avtarPic.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SuperAdminBottomTab from "../SuperAdminBottomTab";
import { makeGetRequest } from "../../../../utils/utils";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";

function SuperAdminDashboard() {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [upload, setUpload] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [selected, setSelected] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("asc");
  const [sortColumnName, setSortColumnName] = React.useState("created_at");

  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };

  async function getAllCompanyList() {
    setShowLoader(true);
    await makeGetRequest("/superadmin/getcompanylist")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            setCompanyList(response.data.data);
          } else {
            setCompanyList([]);
          }
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };
  function handleChangeSortBy() {
    setSortColumnName("full_name");
    setSelected(!selected);
    if (sortBy == "asc") setSortBy("desc");
    else setSortBy("asc");
    setUpload(!upload);
    getAllCompanyList();
  }

  React.useEffect(() => {
    getAllCompanyList();
  }, []);

  return (
    <div className="wrapper innerPagesMain">
      {/* TopBar */}
      <div className="innerPageTopSecBackPatch"></div>
      <div className="innerPageTopSec">
        <div className="forBackArrow">
          {/* <LogoutIcon sx={{ color: 'black', fontSize: 30 }} onClick={logoutFunction} /> */}
        </div>
        <h1>Super Admin Dashboard</h1>
        <IconButton
          className="addEmpTpBt"
          onClick={() => navigate("/AddCompanyAdmin")}
        >
          <AddIcon />
        </IconButton>
      </div>
      {/* TopBarEnd */}
      {/* <Button className='logginBt' onClick={()=>navigate('/AdminList')}>Admin List</Button> */}
      <div className="listingSearch">
        <input
          type="text"
          placeholder="Search"
          //onChange={(e) => getAllAdminsList(e.target.value)}
        />
        <div className="sortingIcoOut">
          <ToggleButton
            value="check"
            selected={selected}
            onChange={() => {
              handleChangeSortBy();
            }}
          >
            <ImportExportIcon
              className="visitorFilterIco"
              aria-haspopup="true"
            />
          </ToggleButton>
        </div>
      </div>
      <div className="superAddminDashMain">
        <div
          className="companyDiv addCompanyDiv"
          onClick={() => navigate("/AddCompany")} style={{paddingTop:20}}
        >
          <div className="companyAddIco">
            {" "}
            <AddIcon />{" "}
          </div>
          <h1>Add Company</h1>
        </div>
        {companyList.map((option) => (
          <div className="companyDiv">
            <div className="compLogImg">
              {option.company_logo ? (
                <img src={option.company_logo} />
              ) : (
                <img src={companyLogo} />
              )}
            </div>
            <h1>{option.company_name}</h1>

            <div className="copmnyDeleteOuter">
              <img src={editIco}
              onClick={() => navigate("/EditCompany/" + option.company_id)}/>
              <FormControlLabel
                control={
                  <Switch
                    checked={isActive}
                    onChange={(e) =>
                      checkActiveStatus(e.target.checked)
                    }
                  />
                }
              />
               <DeleteIcon />
              </div>
          </div>
        ))}
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
      {/* <Button  onClick={() => navigate("/AddCompany")} className='logginBt stickyBtBottom'>Add Company</Button> */}
      <SuperAdminBottomTab />
    </div>
  );
}

export default SuperAdminDashboard;

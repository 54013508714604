import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, MenuItem } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import calendarIm from "../../../assets/images/calendar.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import FilterIcon from "@mui/icons-material/Filter";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import avtarPic from "../../../assets/images/avtarPic.png";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import avatarPic from "../../../assets/images/avtarPic.png";
import noPic from "../../../assets/images/no-data.png";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import CreateIcon from "@mui/icons-material/Create";
import swal from "sweetalert";
import unlock from "../../../assets/images/unlock.png";
import lock from "../../../assets/images/lock.png";
import moment from "moment/moment";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Slider from "react-slick";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UserBottom from "../UserBottom/UserBottom";
import editICo from "../../../assets/images/edit-icon.svg";

// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// End

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import users from "../../../assets/images/Users.svg";
import AppDrawer from "../../AppDrawer";
import Switch from "@mui/material/Switch";
import Person2Icon from "@mui/icons-material/Person2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import avarPi from "../../../assets/images/avtarPic.png";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ErrorIcon from "@mui/icons-material/Error";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopBar from "../../admin/TopBar";
// End

const MyAvailability = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // For Accordian
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // End

  const settings = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
  };

  const navigate = useNavigate();
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [UserName, setUserName] = useState();
  const [UserId, setUserId] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [selected, setSelected] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("asc");
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [categoryList, setCategoryList] = React.useState([]);
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [weekDay, setWeekDay] = useState("");
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [selectedTaskList, setSelectedTaskList] = React.useState();
  const [searchText, setSearchText] = useState("");
  const [completeIteration, setCompleteIteration] = useState(0);
  const [companypic, setCompanyPic] = useState();

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
    //getEmployeeSchedule(UserId);
  }

  function forProgress() {
    let result = "";
    for (let i = 0; i < completePercentage; i + 10) {
      result = '<li class="is-complete"><span>' + i + "%</span></li>";
    }

    return result;
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some(
        (el) => el.taskid === name && el.taskstatus === "Completed"
      );
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  // End

  //   For Click Count
  // State to keep track of the number of clicks
  const [clickCount, setClickCount] = useState(0);

  const handleskip = async (event, fullname) => {
    swal(
      "Already Assigned",
      `This task is already assigned to ${fullname}`,
      "info",
      {
        timer: 3000,
        buttons: false,
      }
    );
  };

  // Function to handle the click event
  const handleClick = async (index, taskid, empid, taskstatus) => {
    setClickCount((prevCount) => (prevCount + 1) % 4); // Cycles through 0 to 3
    setShowLoader(true);
    let values = {};
    if (taskstatus == "Open") values.taskstatus = "Assigned";
    else if (taskstatus == "Assigned") values.taskstatus = "Completed";
    else if (taskstatus == "Completed") values.taskstatus = "Open";
    else values.taskstatus = "Open";

    values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format("YYYY-MM-DD");

    await makeGetRequest(
      "employeeschedular/selectassigntask/" +
        parseInt(empid) +
        "/" +
        parseInt(taskid) +
        "/" +
        parseInt(dropDownSelect) +
        "/'" +
        startDate +
        "'"
    )
      .then((response) => {
        console.log(
          "new response===>" + JSON.stringify(response.data.data.rows)
        );
        if (response.data.data.rowCount > 0) {
          let result = response.data.data.rows;
          if (result[0].assignid > 0 && result[0].taskstatus != "Completed") {
            makePostRequest("employeeschedular/updateassigntask", values)
              .then((response) => {
                if (response.data) {
                  getSerachList();
                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            makeGetRequest(
              "employeeschedular/deleteassigntask/" +
                parseInt(empid) +
                "/" +
                parseInt(taskid) +
                "/" +
                parseInt(dropDownSelect) +
                "/'" +
                startDate +
                "'"
            )
              .then((response) => {
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              })
              .catch((err) => {
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              });
          }
        } else {
          if (values.taskstatus != "Open") {
            makePostRequest("employeeschedular/insertassigntask", values)
              .then((response) => {
                if (response.data) {
                  getSerachList();
                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            getSerachList();
            setShowLoader(false);
          }
        }
      })
      .catch((err) => {});
  };

  // const getAssignEmployeeTask = async (startDate) => {

  //   await makeGetRequest("employeeschedular/getassignedEmployeestask/'"+startDate+"'").then((response) => {
  //     console.log("selected response===>",JSON.stringify(response.data.data.rows));
  //     setSelectedTaskList(response.data.data.rows)
  //   }).catch((err) => {

  //   });

  // }

  // Determine the class based on click count

  // End
  const [employeeImage, setEmployeeImage] = useState("");
  const [employeeImageErrMsg, setEmployeeImageErrMsg] = React.useState("");
  const [localImage, setLocalImage] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState([]);

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;
    await uploadFileRequest("superadmin/upload", formData).then((response) => {
      fileUrl = response.data.imagepath;
    });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
    console.log("fileData", fileData.size);
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      if (fileData.size < 1069000) return true;
      else setShowLoader(false);
      return false;
    } else return false;
  }
  const [ConImg, setConImg] = useState();
  /* const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        async(uri) => {
          resolve(uri);
          //console.log(uri);
        },
        "base64"
      );
    }); */

  async function uploadImage(e) {
    setEmployeeImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var empImg = await uploadImageOrFile(file);
      let id = localStorage.getItem("userId");
      //setEmployeeImage(empImg);
      uploadProfilepic(empImg, id);
    } else {
      setEmployeeImageErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    //updateImage();
    setShowLoader(false);
  }

  const uploadProfilepic = (Img, id) => {
    console.log(Img);
    console.log(id);

    const formData = {
      employee_id: parseInt(id),
      profile_pic: Img,
      contact_no: employeeDetails.contact_no,
      email_id: employeeDetails.email_id,
      company_id: employeeDetails.company_id,
      employee_code: employeeDetails.employee_code,
    };
    makePostRequest("employee/updateemployee", formData)
      .then((response) => {
        if (response.data) {
          swal("Success", "Image updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
        }
        let id = localStorage.getItem("userId");
        getEmployeeDetails(id);
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
  };

  const getEmployeeDetails = async (id) => {
    setShowLoader(true);
    await makeGetRequest("/employee/getemployee/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0]);
            setEmployeeDetails(response.data.data[0]);
          } else setEmployeeDetails([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const forSelectList = () => {
    var element = document.getElementById("listID");
    element.classList.toggle("selectedList");
  };

  const getSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      setShowLoader(true);
      await makeGetRequest(
        "employeeschedular/employeeassigntask/" +
          UserId +
          "/'" +
          startDate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const getEmployeeSchedule = (id) => {
    setShowLoader(true);
    //setCategoryID(id)

    makeGetRequest(
      "employeeschedular/employeesschedular/" + id + "/'" + startDate + "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          //alert(response.data.data.rows[0].categories.length);
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);
            //setWeekDay("Wednesday")
            //setSelectedTaskList(response.data.data.res.rows)
            //setIncValue(response.data.data.totalCount)
            setShowLoader(false);
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getEmployeeSchedulewithexisting = (id, catids) => {
    setShowLoader(true);
    makeGetRequest(
      "employeeschedular/employeesschedular/" + id + "/'" + startDate + "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);

            setShowLoader(true);
            makeGetRequest(
              "employeeschedular/employeeassigntask/" +
                id +
                "/'" +
                startDate +
                "'/" +
                catids +
                "/'" +
                response.data.data.rows[0].weekday +
                "'" +
                "/" +
                sortColumnName +
                "/" +
                sortBy +
                "/" +
                null
            )
              .then((response) => {
                //console.log(response.data.data.rows);
                if (response.data.data.res.rows) {
                  //console.log(response.data.data);
                  //alert(response.data.data.rows[0].categories.length);
                  if (response.data.data.res.rows.length > 0) {
                    setEmployeeTaskList(response.data.data.res.rows);
                    setCompleteCount(
                      response.data.data.rescounts.rows[0].total_count
                    );
                    let totalTask = response.data.data.res.rowCount;
                    let completedTask =
                      response.data.data.rescounts.rows[0].total_count;
                    let Total = (completedTask * 100) / totalTask;
                    let FinalTotal = Math.round(Total);
                    setCompletePercentage(FinalTotal);
                    const iterations = FinalTotal / 10;
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  } else {
                    setEmployeeTaskList([]);
                    setCompletePercentage(0);
                    const iterations = 0;
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  }
                  setUpload(!upload);
                }
              })
              .catch((err) => {
                setShowLoader(false);
              });
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const updateTaskSchedular = () => {
    console.log("ImgVariable", imageUploadVariables);
    setImageUploadAfter([]);
    setImageUploadBefore([]);
    setShowLoader(false);
    /* values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format('YYYY-MM-DD'); */
    let formData = {
      taskid: imageUploadVariables.taskid,
      empid: parseInt(imageUploadVariables.userid),
      categoryid: parseInt(dropDownSelect),
      before_img: ImageUploadBefore,
      after_img: ImageUploadAfter,
      taskassigndatetime: moment().format("YYYY-MM-DD"),
    };
    makePostRequest("employeeschedular/updateassigntask", formData)
      .then((response) => {
        if (response.data) {
          getSerachList();
          setLoginErrMsg("");
          setShowLoader(false);
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          handleClose();
          setImageUploadAfter([]);
          setImageUploadBefore([]);
        }
      })
      .catch((err) => {
        let errMsg = err.message;
        setShowLoader(false);
        setLoginErrMsg(errMsg);
      });

    /* makePostRequest("employeeschedular/updatetaskschedular", formData)
    .then((response) => {
      setShowLoader(false);
      if (response.data) {
        swal("Success", "Information updated successfully", "success", {
          timer: 3000,
          buttons: false,
        });
      }
    })
    .catch((err) => {
      setShowLoader(false);
      swal("Warning", err.response.data.message, "warning", {
        timer: 3000,
        buttons: false,
      });
    }); */
  };

  const [imageBeforeErrMsg, setImageBeforeErrMsg] = React.useState("");
  const [imageAfterErrMsg, setImageAfterErrMsg] = React.useState("");

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function uploadImagesBefore(e) {
    setImageBeforeErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];

    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setImageUploadBefore([...ImageUploadBefore, logoUrl]);
    } else {
      setImageBeforeErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    setShowLoader(false);
  }

  async function uploadImagesAfter(e) {
    setImageAfterErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];

    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setImageUploadAfter([...ImageUploadAfter, logoUrl]);
    } else {
      setImageAfterErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    setShowLoader(false);
  }

  const deleteImgBefore = (index) => {
    setShowLoader(true);
    ImageUploadBefore.splice(index, 1);
    setImageUploadBefore([...ImageUploadBefore]);
    setShowLoader(false);
  };

  const deleteImgAfter = (index) => {
    setShowLoader(true);
    ImageUploadAfter.splice(index, 1);
    setImageUploadAfter([...ImageUploadAfter]);
    setShowLoader(false);
  };

  // For Modal
  const [open, setOpen] = React.useState(false);

  const [imageUploadVariables, setImageUploadVariables] = useState({
    taskid: "",
    userid: "",
  });
  const handleClickOpen = (taskid, userId, taskObj) => {
    console.log("TaskId", taskid, "Userid", userId);
    console.log(taskObj);
    if (taskObj.assigntask != undefined) {
      if (taskObj.assigntask[0].empid == userId) {
        if (taskObj.assigntask[0].before_img != null)
          setImageUploadBefore([...taskObj.assigntask[0].before_img]);
        else setImageUploadBefore([]);
        if (taskObj.assigntask[0].after_img != null)
          setImageUploadAfter([...taskObj.assigntask[0].after_img]);
        else setImageUploadAfter([]);
        setImageUploadVariables({
          taskid: taskid,
          userid: userId,
        });
        setOpen(true);
      } else {
        swal(
          "This Task is Already Assigned to Someone else ",
          "Warning",
          "warning",
          {
            timer: 3000,
            buttons: false,
          }
        );
        setOpen(false);
      }
    } else {
      swal("Please assign Task First", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setImageUploadVariables({
      taskid: "",
      userid: "",
    });
    setImageUploadAfter([]);
    setImageUploadBefore([]);
  };
  // End

  useEffect(() => {
    let catsetids = localStorage.getItem("categorysetid");
    setDropDownSelect(catsetids);
    if (parseInt(catsetids) > 0) {
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");
      getEmployeeSchedulewithexisting(id, parseInt(catsetids));
      setInterval(() => setTodaysDate({ time: new Date() }), 1000);
      setUserName(localStorage.getItem("UserName"));
      setLocalImage(localStorage.getItem("adminProfilePic"));
    } else {
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");

      getEmployeeSchedule(id);
      //alert(moment(todaysDate.time).format("YYYY-MM-DD"));
      setInterval(() => setTodaysDate({ time: new Date() }), 1000);
      setUserName(localStorage.getItem("UserName"));
      setLocalImage(localStorage.getItem("adminProfilePic"));
    }
    let id = localStorage.getItem("userId");
    setCompanyPic(localStorage.getItem("companyLogo"));
    getEmployeeDetails(id);
  }, [employeeImage]);

  useEffect(() => {
    const initialStartDate = mondays[0].format("YYYY-MM-DD");
    const initialEndDate = mondays[0]
      .clone()
      .add(6, "days")
      .format("YYYY-MM-DD");

    setDateRange({ startDate: initialStartDate, endDate: initialEndDate });

    // Call API with the initial date range
    getPredefinedScheduleEmployee(initialStartDate, initialEndDate);
    getEmpAvailabilityPrefData(
      initialStartDate,
      initialEndDate,
      parseInt(localStorage.getItem("userId"))
    );
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //code by yamini
  const [weeklyschedule, SetWeeklySchedule] = React.useState({});
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getEmpAvailabilityPrefData = async (startDate, endDate) => {
    let formData = {
      emp_id: parseInt(localStorage.getItem("userId")),
      startdate: startDate, // Pass the start date
      enddate: endDate, // Pass the end date
    };
    setShowLoader(true);

    makePostRequest("employee/getEmpAvailabilityPrefData", formData)
      .then((response) => {
        setShowLoader(false);
        if (response.data.data && response.data.data.length > 0) {
          // Data found, set prefdata with the first item
          setPrefdata(response.data.data[0]);
        } else {
          // Data is an empty array, handle it by setting prefdata to default values
          setPrefdata({
            no_of_hours: "",
            no_of_shifts: "",
          });
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const [sch_id, setSch_id] = React.useState("");
  const getPredefinedScheduleEmployee = async (startDate, endDate) => {
    let formData = {
      emp_id: parseInt(localStorage.getItem("userId")),
      startDate: startDate, // Pass the start date
      endDate: endDate, // Pass the end date
    };
    setShowLoader(true);

    makePostRequest(
      "schedular/getWeeklyScheduleForEmpWithAvailbilityStatus",
      formData
    )
      .then((response) => {
        const scheduleData = response?.data?.data;
        const newSelectedEmployees = {}; // Initialize an object to store the preselected employees
        const newCheckedDays = {}; // Initialize an object to store checkedDays state
        let schidarr = [];
        if (scheduleData && Object.keys(scheduleData).length > 0) {
          // Iterate through each day's data and populate selectedEmployees state based on is_emp_available
          Object.keys(scheduleData).forEach((day) => {
            let allShiftsSelected = true; // Assume all shifts are selected for this day

            scheduleData[day].forEach((sch) => {
              const location = sch.location;
              if (!schidarr.includes(sch.sch_id)) {
                schidarr.push(sch.sch_id);
              }

              if (!newSelectedEmployees[day]) {
                newSelectedEmployees[day] = {};
              }
              if (!newSelectedEmployees[day][location]) {
                newSelectedEmployees[day][location] = {
                  sch_id: sch.sch_id,
                  shifts: {},
                };
              }

              sch.shifts.forEach((shift) => {
                if (shift.is_emp_available) {
                  newSelectedEmployees[day][location].shifts[
                    shift.shift_timing
                  ] = {
                    emp_id: employeeDetails.employee_id,
                    profile_pic: employeeDetails.profile_pic,
                    name: employeeDetails.full_name,
                    is_selected: true, // Set is_selected to true for initial checked state
                  };
                } else {
                  allShiftsSelected = false; // If any shift is not selected, set to false
                }
              });
            });

            // After processing all shifts for this day, update the checkedDays state
            newCheckedDays[day] = allShiftsSelected; // true if all shifts were selected, false otherwise
          });
          setSch_id(schidarr);
          SetWeeklySchedule(scheduleData); // Set the weekly schedule data
          setSelectedEmployees(newSelectedEmployees); // Update the state with is_emp_available
          setCheckedDays(newCheckedDays); // Set the checkedDays state based on shifts selection
          setShowLoader(false);
          checkAvailability(startDate, schidarr);
        } else {
          SetWeeklySchedule({});
          setSelectedEmployees({});
          setCheckedDays({}); // Reset checkedDays if no schedule data
          setShowLoader(false);
        }
      })
      .catch((err) => {
        SetWeeklySchedule({});
        setSelectedEmployees({});
        setCheckedDays({}); // Reset checkedDays if no schedule data
        setShowLoader(false);
      });
  };

  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [checkedDays, setCheckedDays] = useState({});

  const [checkboxChange, SetCheckboxChange] = React.useState(false);

  const handleDayCheckboxChange = (day) => {
    SetCheckboxChange(true);
    setCheckedDays((prevCheckedDays) => {
      const newCheckedDays = {
        ...prevCheckedDays,
        [day]: !prevCheckedDays[day],
      };

      const shiftsForDay = weeklyschedule[day] || [];

      setSelectedEmployees((prevState) => {
        const newState = { ...prevState };

        shiftsForDay.forEach((sch) => {
          const location = sch.location;

          if (!newState[day]) newState[day] = {};
          if (!newState[day][location]) {
            newState[day][location] = { sch_id: sch.sch_id, shifts: {} };
          }

          sch.shifts.forEach((shift) => {
            const shiftTiming = shift.shift_timing;

            if (newCheckedDays[day]) {
              // Set is_selected to true if day is checked
              newState[day][location].shifts[shiftTiming] = {
                emp_id: employeeDetails.employee_id,
                profile_pic: employeeDetails.profile_pic,
                name: employeeDetails.full_name,
                is_selected: true,
              };
            } else {
              // Set is_selected to false if day is unchecked
              if (newState[day][location].shifts[shiftTiming]) {
                newState[day][location].shifts[shiftTiming].is_selected = false;
              }
            }
          });
        });

        return newState;
      });

      return newCheckedDays;
    });
  };

  const handleCheckboxChange = (
    day,
    sch_id,
    location,
    shift,
    isSelected,
    empdetails
  ) => {
    SetCheckboxChange(true);
    setSelectedEmployees((prevState) => {
      const newState = { ...prevState };

      if (!newState[day]) newState[day] = {};
      if (!newState[day][location]) {
        newState[day][location] = { sch_id: sch_id, shifts: {} };
      }

      // Add or update the entry based on `isSelected`
      newState[day][location].shifts[shift.shift_timing] = {
        emp_id: empdetails.employee_id,
        profile_pic: empdetails.profile_pic,
        name: empdetails.full_name,
        is_selected: isSelected, // Track selection state here
      };

      return newState;
    });
  };

  const [prefdata, setPrefdata] = useState({
    no_of_hours: "",
    no_of_shifts: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrefdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSavePref = async () => {
    let formData = {
      emp_id: parseInt(localStorage.getItem("userId")),
      startdate: dateRange.startDate, // Pass the start date
      enddate: dateRange.endDate, // Pass the end date
      no_of_hours: prefdata.no_of_hours,
      no_of_shifts: prefdata.no_of_shifts,
    };
    setShowLoader(true);

    makePostRequest("employee/InsertOrUpdateEmpPref", formData).then(
      (response) => {
        if (response.data.data) {
          setShowLoader(false);
          swal("Success", "Data saved successfully", "success", {
            timer: 1000,
            buttons: false,
          });
        } else {
          setShowLoader(false);
          swal("Error", "Something went wrong", "error", {
            timer: 1000,
            buttons: false,
          });
        }
      }
    );
  };

  const handleSaveBatch = async () => {
    try {
      console.log("Selected Employees:", selectedEmployees);

      // Prepare to collect the data to be sent to the API
      const savePayloads = [];

      // Map weekdays to their indices
      const dayIndexMap = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6,
      };

      for (const day in selectedEmployees) {
        for (const location in selectedEmployees[day]) {
          const { sch_id, shifts } = selectedEmployees[day][location];

          for (const shiftTime in shifts) {
            const { is_selected, ...employeeInfo } = shifts[shiftTime]; // Separate `is_selected` from employee data

            const weekdate = weekDates[dayIndexMap[day]]; // Get the week date for the current day
            if (!weekdate) {
              console.error(`Week date for ${day} is undefined.`);
              continue; // Skip this iteration if weekdate is not valid
            }

            const formattedWeekdate = moment(weekdate).format("YYYY-MM-DD");

            savePayloads.push({
              weekday: day,
              weekdate: formattedWeekdate,
              location,
              shifttime: shiftTime,
              sch_id,
              emp_info: [employeeInfo],
              is_selected,
            });
          }
        }
      }
      // Call the API only once with the batch of all payloads
      const response = await makePostRequest(
        "employee/updateEmployeeAvailabilityBatch",
        { data: savePayloads }
      );

      if (response.data) {
        SetCheckboxChange(false);
        swal("Success", "Data saved successfully", "success", {
          timer: 1000,
          buttons: false,
        });
        resetSelections(); // Reset selections after saving
      } else {
        swal("Error", "Failed to save data", "error");
      }
    } catch (error) {
      console.error("Error during save:", error);
      if (error.response) {
        swal(
          "Error",
          error.response.data.message || "No message provided",
          "error"
        );
      } else {
        swal("Error", "An unexpected error occurred", "error");
      }
    }
  };

  const resetSelections = () => {
    getPredefinedScheduleEmployee(dateRange.startDate, dateRange.endDate);
    //checkAvailability()
  };

  const getMondays = (numWeeks) => {
    const mondays = [];
    let currentDate = moment(); // Get the current date

    // Find the previous Monday (or current day if it's Monday)
    // const currentMonday = currentDate.startOf("week").add(1, "days");
    const nextMonday = currentDate
      .startOf("week")
      .add(1, "weeks")
      .add(1, "days");
    for (let i = 0; i < numWeeks; i++) {
      mondays.push(nextMonday.clone().add(i * 7, "days"));
    }

    return mondays;
  };

  // Generate an array of Mondays for the next 'n' weeks
  const mondays = getMondays(100); // Adjust the number of weeks if needed

  // Function to calculate the current week's dates
  // const getCurrentWeekDates = () => {
  //   const startOfWeek = moment().startOf('week').add(1, 'days'); // Monday as the start of the week
  //   const weekDates = [];

  //   for (let i = 0; i < 7; i++) {
  //     weekDates.push(startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD')); // Format as YYYY-MM-DD
  //   }

  //   return weekDates;
  // };

  // const weekDates = getCurrentWeekDates();
  // Function to calculate the current week's start and end dates
  const getWeekStartEndDates = () => {
    const startOfWeek = moment().startOf("week").add(1, "days"); // Monday as the start of the week
    const endOfWeek = startOfWeek.clone().add(6, "days"); // Sunday as the end of the week

    return {
      start: startOfWeek.format("YYYY-MM-DD"), // Format as YYYY-MM-DD
      end: endOfWeek.format("YYYY-MM-DD"), // Format as YYYY-MM-DD
    };
  };

  const { start, end } = getWeekStartEndDates();

  const weekdayMap = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
  };

  const [availability, setAvailability] = React.useState([]);

  const weekdays = Object.keys(weekdayMap); // ["M", "T", "W", "T", "F", "S", "Su"]

  const checkAvailability = async (startDate, schidarr) => {
    console.log("sch_id to be passed" + sch_id);
    try {
      const availabilityResults = {};

      // Convert startDate to moment object
      const startMoment = moment(startDate);

      // Loop through the abbreviated weekdays
      for (const day of weekdays) {
        const fullDay = weekdayMap[day]; // Get the full name from the map

        // Calculate the specific weekdate for the current day
        const weekdate = startMoment.clone().day(fullDay); // Format as YYYY-MM-DD
        // If the calculated date is before the start date, add 7 days to move to the next week
        if (weekdate.isBefore(startMoment)) {
          weekdate.add(7, "days");
        }

        // Format as YYYY-MM-DD
        const formattedWeekdate = weekdate.format("YYYY-MM-DD");

        let formData = {
          emp_id: parseInt(localStorage.getItem("userId")),
          weekday: fullDay,
          weekdate: formattedWeekdate, // Include the calculated weekdate
          sch_id: schidarr,
        };

        console.log(formData);
        const response = await makePostRequest(
          "employee/showWeekdayAvaiblityStatustoEmp",
          formData
        );

        availabilityResults[day] = response.data.available; // true or false
      }

      setAvailability(availabilityResults);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const [selectedMonday, setSelectedMonday] = useState(mondays[0]); // Default to the first Monday
  const getCurrentWeekDates = (startDate) => {
    const weekDates = [];
    const startOfWeek = moment(startDate);

    for (let i = 0; i < 7; i++) {
      weekDates.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
    }

    return weekDates;
  };

  // Define `dateRange` with initial values as today's week range
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
  });

  // Weekday dates will dynamically update based on `dateRange.startDate`
  const weekDates = getCurrentWeekDates(dateRange.startDate);

  // Handle Monday change from the slider
  const handleMondayChange = (newMonday) => {
    const startDate = newMonday.format("YYYY-MM-DD");
    const endDate = newMonday.clone().add(6, "days").format("YYYY-MM-DD");

    setDateRange({ startDate, endDate }); // Update state with the new date range

    // Call API with the updated date range
    getPredefinedScheduleEmployee(startDate, endDate);
    getEmpAvailabilityPrefData(
      startDate,
      endDate,
      parseInt(localStorage.getItem("userId"))
    );
    // Call the checkAvailability function with the new date range
    // checkAvailability(startDate);
  };

  const [checked, setChecked] = React.useState(true);
  const handleSwitchCheck = (event) => {
    if (event.target.checked == true) {
      setChecked(event.target.checked);
      navigate("/EmployeeTaskListNew");
    } else {
      navigate("/ReportAdmin");
    }
  };

  //end code

  return (
    <>
      <TopBar />
      <div className="employeeTaskListMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          {/* <div className="forBackArrow">
            <AppDrawer />
            <NavigateBeforeIcon onClick={() => navigate("/")} />
          </div> */}
          <h1>
            <marquee>My Availability</marquee>
          </h1>
          {localStorage.getItem("userType") == "Admin" ? (
            <div className="switchAccount profile-switcher">
              <div className="acount1" onClick={() => navigate("/ReportAdmin")}>
                <AdminPanelSettingsIcon />
              </div>
              <Switch
                checked={checked}
                onChange={handleSwitchCheck}
                {...label}
              />
              <div
                className="acount1 actPro"
                onClick={() => navigate("/EmployeeTaskListNew")}
              >
                <Person2Icon />
              </div>
            </div>
          ) : null}
        </div>
        {/* TopBarEnd */}

        <div className="adminLoginBody ">
          <div className="myAvailabilityMain">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <div className="mySlik createScheduSlik">
                  <div className="slider-container">
                    <Slider
                      {...settings}
                      afterChange={(index) =>
                        handleMondayChange(mondays[index])
                      }
                    >
                      {mondays.map((monday, index) => (
                        <div key={index}>
                          <h3>Mon</h3>
                          <span>{monday.format("MM/DD/YYYY")}</span>{" "}
                          {/* Format the date as desired */}
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div
                  className="setDayMain myAvailibiSetDay"
                  style={{ marginBottom: 10 }}
                >
                  {weekdays.map((day, index) => (
                    <div
                      key={index}
                      className={`dayInD ${availability[day] ? "activDs" : ""}`} // Add active class if available
                    >
                      {day} {/* Display abbreviated day */}
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="schedularAccordianMain setPrefeMain">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Set your preference:
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <input
                          placeholder="Maximum hours per week"
                          name="no_of_hours"
                          value={prefdata.no_of_hours}
                          onChange={handleInputChange}
                          type="number"
                        />
                        <input
                          placeholder="Maximum shifts per week"
                          name="no_of_shifts"
                          value={prefdata.no_of_shifts}
                          onChange={handleInputChange}
                          type="number"
                        />
                        <Button onClick={handleSavePref}>Save</Button>
                        <p className="noteTs">
                          <b>Please note,</b> setting these preferences doesn't
                          guarantee the preferred number of hours or shifts
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="weeklyAvailibMain">
                  <label>Weekly Availability From</label>
                  <h3>
                    {dateRange.startDate} <ArrowForwardIcon />{" "}
                    {dateRange.endDate}
                  </h3>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                {Object.keys(weeklyschedule).map((day, index) => (
                  <div key={index}>
                    {weeklyschedule[day] && weeklyschedule[day].length > 0 ? (
                      <>
                        <div
                          className="schedulAcordianMain"
                          style={{ marginBottom: 20 }}
                        >
                          <div className="schedulAcordianInr">
                            {/* Render the day checkbox */}
                            <div className="schedulAcordianTp">
                              <b className="availabilityTpNew">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkedDays[day] || false}
                                      onChange={() =>
                                        handleDayCheckboxChange(day)
                                      }
                                    />
                                  }
                                  label={day}
                                />
                                <span>
                                  {" "}
                                  (
                                  {weeklyschedule[day]?.reduce(
                                    (acc, schobj) => acc + schobj.shift_count,
                                    0
                                  )}{" "}
                                  Shifts)
                                </span>
                              </b>
                              <div className="schedulAcordianTpRight">
                                <span className="schedulAcordianTpRiTx">
                                  {weekDates[index]}{" "}
                                  {/* Access the correct date using the index */}
                                </span>
                              </div>
                            </div>

                            {/* Loop over locations and render their shifts */}
                            {weeklyschedule[day].map(
                              (schobj, locationIndex) => (
                                <div
                                  className="schedulAcordianCont"
                                  key={locationIndex}
                                >
                                  <div className="dayWMain">
                                    <h1>{schobj.location}</h1>

                                    {/* Loop over shifts for the location */}
                                    {schobj.shifts.map((shift, shiftIndex) => (
                                      <div className="dayWInr" key={shiftIndex}>
                                        <p>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={
                                                  selectedEmployees[day]?.[
                                                    schobj.location
                                                  ]?.shifts[shift.shift_timing]
                                                    ?.is_selected ?? false
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    day,
                                                    schobj.sch_id,
                                                    schobj.location,
                                                    shift,
                                                    e.target.checked,
                                                    employeeDetails
                                                  )
                                                }
                                              />
                                            }
                                            label={shift.shift_timing}
                                          />
                                        </p>
                                        <span>{shift.shift_hours} hrs</span>
                                        <b>
                                          <PeopleAltOutlinedIcon />{" "}
                                          {shift.emp_count}
                                        </b>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )
                            )}

                            {/* Save Button */}
                            {/* <Button className="logginBt" onClick={handleSave}>Save</Button> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="noScheAvailabMain">
                        <ErrorIcon />{" "}
                        <span>
                          No schedule available for <b>{day}</b>
                        </span>
                      </div>
                    )}
                  </div>
                ))}

                <Button
                  className="logginBt stickyBtBottom"
                  onClick={handleSaveBatch}
                  disabled={!checkboxChange}
                >
                  Save
                </Button>

                {/* </Slider> */}
                {/* </div>
                </div> */}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <UserBottom />

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore.length < 4 ? (
                        <div className="befAftImgDiv">
                          <img src={afterBef1} />
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesBefore(e)}
                          >
                            <input type="file" />
                            Click Picture
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadBefore.length > 0 ? (
                        <>
                          {ImageUploadBefore.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              <img src={imgObj} />
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgBefore(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageBeforeErrMsg}</p>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter.length < 4 ? (
                        <div className="befAftImgDiv">
                          <img src={afterBef2} />
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesAfter(e)}
                          >
                            <input type="file" />
                            Click Picture
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadAfter.length > 0 ? (
                        <>
                          {ImageUploadAfter.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              <img src={imgObj} />
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgAfter(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageAfterErrMsg}</p>
                    </div>
                  </div>
                  <Grid item xs={12} md={12}>
                    <Button className="logginBt" onClick={updateTaskSchedular}>
                      {" "}
                      Submit
                    </Button>
                  </Grid>
                  {showLoader ? (
                    <div className="loaderNew">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyAvailability;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import { makeGetRequest } from "../../../utils/utils";
import PDF from "../../../assets/images/pdfSvg.svg";
import TXT from "../../../assets/images/textSvg.svg";
import UserBottom from "../../employee/UserBottom/UserBottom";

function TaskDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};
  const [showLoader, setShowLoader] = React.useState(false);
  const [taskname, setTaskname] = React.useState("");
  const [taskdescription, setTaskDescription] = React.useState("");
  const [imageUpload, setImageUpload] = useState([]);
  const [documentUpload, setDocumentUpload] = React.useState([]);
  const [isbefore, setIsBefore] = React.useState("");
  const [ismultiperson, setIsMultiPerson] = React.useState("");

  const getDetails = (Id) => {
    if (Id) {
      setShowLoader(true);
      makeGetRequest("task/gettask/" + Id)
        .then((response) => {
          if (response.data) {
            setTaskname(response.data.data[0].task_name);
            setTaskDescription(response.data.data[0].task_description);
            setImageUpload(response.data.data[0].media);
            setDocumentUpload(response.data.data[0].file);
            if(response.data.data[0].is_beforeafter=="false" || response.data.data[0].is_beforeafter==false)
              setIsBefore("No")
            else
            setIsBefore("Yes")

            if(response.data.data[0].is_multiple_person_task=="false" || response.data.data[0].is_multiple_person_task==false)
              setIsMultiPerson("No")
            else
            setIsMultiPerson("Yes")

            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    if (Id) getDetails(Id);
  }, []);

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Task Details</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain newTaskDetailMain">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h1 className="tskHeading">{taskname}</h1>
            </Grid>
            <Grid item xs={12} md={12}>
              <label className="myLable">Description</label>
              <>
                {taskdescription !== null ? (
                  <div
                    className="tskDiscrip"
                    dangerouslySetInnerHTML={{ __html: `${taskdescription}` }}
                  />
                ) : null}
              </>
            </Grid>

            <Grid item xs={12} md={12}>
              <label className="myLable">Does it need Before and After photos?</label>
              <div className="tskDiscrip">{isbefore}</div>  
            </Grid>


            <Grid item xs={12} md={12}>
              <label className="myLable">Is the task assigned to multiple people?</label>
              <div className="tskDiscrip">{ismultiperson}</div>  
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="filesTailMain">
                <label className="myLable">Images or Videos</label>
                <div className="uploadedImMain">
                  {imageUpload ? (
                    <>
                      {imageUpload.map((uploadImg, index) => (
                        <div className="uploadedImInr">
                          {uploadImg.match(
                            /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i
                          ) ? (
                            <div>
                              <Zoom>
                                <img
                                  src={uploadImg}
                                  style={{ width: "100%" }}
                                />
                              </Zoom>
                            </div>
                          ) : uploadImg.match(
                              /.(mp4|MP4|3gp|3GP|flv|FLV)$/i
                            ) ? (
                            <div
                              className="videoPlaMain"
                              //style={{ height: "100%" }}
                            >
                              <video width="100%" height="100%" controls>
                                <source src={uploadImg} type="video/mp4" />
                              </video>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="filesTailMain">
                <label className="myLable">Files</label>
                <div className="uploadedImMain docUpladPreviewMain">
                  {documentUpload ? (
                    <>
                      {documentUpload.map((uploadDoc, index) => (
                        <div className="uploadedImInr" key={index}>
                          {uploadDoc.match(/.(pdf|PDF)$/i) ? (
                            <div>
                              <a href={uploadDoc} id="pdfLink" target="_blank">
                                <img src={PDF} />
                              </a>
                            </div>
                          ) : uploadDoc.match(/.(txt|TXT)$/i) ? (
                            <div>
                              <a href={uploadDoc} id="pdfLink" target="_blank">
                                <img src={TXT} />
                              </a>
                            </div>
                          ) : uploadDoc.match(/.(docx|DOCX|doc|DOC)$/i) ? (
                            <div>
                              <a href={uploadDoc} id="pdfLink" target="_blank">
                                <img src={TXT} />
                              </a>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <div className="tskBuletPoints">
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em>{" "}
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum is simply dummy text</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum dummy text</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em>{" "}
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry
                  </span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum is simply dummy text</span>
                </div>
                <div className="tskBuletPointsInr">
                  <em></em> <span>Lorem Ipsum dummy text</span>
                </div>
              </div>
            </Grid> */}
          </Grid>
        </div>
        {/* {localStorage.getItem("userType") == "Admin" ? (
          <AdminBottomNav />) :(<UserBottom />)} */}
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default TaskDetails;

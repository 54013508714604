import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import swal from "sweetalert";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import { makePostRequest } from "../../../utils/utils";

function AddFrequency() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const [text, setText] = useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");

  const [isEditFrequency, setIsEditFrequency] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [starttime, setStartTime] = useState("07:00");
  const [endtime, setEndTime] = useState("23:00");

  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };

  const editFrequency = (data) => {
    console.log("Call");
    setIsEditFrequency(true);
    setIsActive(data.status);
    //setStartTime(data.start_time);
    //setEndTime(data.end_time);
    if (data) {
      setFormValues(data);
    } else setFormValues(null);
  };

  const initialValues = {
    frequancy_name: "",
    //start_time: starttime,
    //end_time: endtime,
  };

  const validationSchema = Yup.object({
    frequancy_name: Yup.string().trim().required("Required"),
    //start_time: Yup.string().required("Required"),
    //end_time: Yup.string().required("Required"),
  });

  const [formValues, setFormValues] = React.useState({
    frequancy_name: "",
    //start_time: "",
    //end_time: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const onSubmit = async (values) => {
    console.log(values);
    if (values.frequancy_name == "Any" || values.frequancy_name == "any") {
      swal("" + "Info", "Any option is already in system", "info", {
        timer: 2000,
        buttons: false,
      });
    } else {
      console.log("Form Data", values);
      if (isEditFrequency) {
        setShowLoader(true);
        values.frequancyid = data.frequancyid;
        values.is_deleted = false;
        values.status = isActive;
        console.log(values);
        await makePostRequest("frequancy/updatefrequancy", values)
          .then((response) => {
            console.log("error with response===>" + response);
            if (response.data) {
              console.log(response);
              setLoginErrMsg("");
              setShowLoader(false);
              localStorage.setItem("TabName", "frequency");
              navigate("/AdminTasksList");
            }
          })
          .catch((err) => {
            console.log("Err");
            console.log(err);
            let errMsg = err.message;
            console.log(errMsg);
            setShowLoader(false);
            setLoginErrMsg(errMsg);
          });
      } else {
        setShowLoader(true);
        setLoginErrMsg("");
        values.status = true;
        values.is_deleted = false;

        await makePostRequest("frequancy/insertfrequancy", values)
          .then((response) => {
            console.log("error with response===>" + response);
            if (response.data) {
              console.log(response);
              setLoginErrMsg("");
              setShowLoader(false);
              localStorage.setItem("TabName", "frequency");
              navigate("/AdminTasksList");
            }
          })
          .catch((err) => {
            console.log("Err");
            console.log(err);
            let errMsg = err.message;
            console.log(errMsg);
            setShowLoader(false);
            setLoginErrMsg(errMsg);
          });
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("TabName", "frequency");
    if (data) editFrequency(data);
  }, []);

  return (
    <>
      <div className="wrapper innerPagesMain">
        <div className="forUpperBottomBt">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow">
              <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
            </div>
            {isEditFrequency === true ? (
              <h1>Edit Employee Shift</h1>
            ) : (
              <h1>Add Employee Shift</h1>
            )}
            <div className="forBackArrow"></div>
          </div>
          {/* TopBarEnd */}

          <div className="addAdminEmployeeMain">
            <Formik
              initialValues={formValues || initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {(formik) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Enter Employee Shift Name"
                        placeholder="Employee Shift Name"
                        labelClass="myLable"
                        name="frequancy_name"
                        className="myInput"
                        onChange={(e) => handleChange(e)}
                        isRequired="true"
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="time"
                        placeholder="12:00 pm"
                        label="Start Date"
                        labelClass="myLable"
                        name="start_time"
                        className="myInput"
                        //value={starttime}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="time"
                        label="End Date"
                        labelClass="myLable"
                        name="end_time"
                        className="myInput"
                        //value={endtime}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={12}>
                      <div className="mySwitch">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isActive}
                              onChange={(e) =>
                                checkActiveStatus(e.target.checked)
                              }
                            />
                          }
                          label="Active"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <div className="upperBottomBt">
                        <Button type="submit" className="logginBt">
                          {isEditFrequency === true ? (
                            <>Update</>
                          ) : (
                            <>Add Employee Shift</>
                          )}
                        </Button>
                      </div>
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                    <h1 className="bodyHeadignAdmin">Recently Added</h1>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="employyeListingMain">
                      <div className="emploTaskListInr">
                        <div>
                          <h1>Task Name</h1>
                        </div>
                        <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon />
                        </div>
                      </div>
                    </div>
                  </Grid> */}
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>

          <AdminBottomNav />
        </div>
      </div>
    </>
  );
}

export default AddFrequency;

import React, { useState } from "react";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

const HideAndShowRowDemo = () => {
  const [rows, setRows] = useState([
    { id: 1, name: "John Doe", age: 28, hidden: false },
    { id: 2, name: "Jane Smith", age: 34, hidden: false },
    { id: 3, name: "Sam Wilson", age: 22, hidden: false },
  ]);

  const [lastTap, setLastTap] = useState(null);

  // Function to handle double-tap or double-click
  const handleRowInteraction = (id) => {
    const now = Date.now();
    if (lastTap && now - lastTap < 300) {
      hideRow(id);
    }
    setLastTap(now);
  };

  // Function to hide a row
  const hideRow = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, hidden: true } : row))
    );
  };

  // Function to show a specific hidden row
  const showHiddenRow = (id) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, hidden: false } : row))
    );
  };

  return (
    <div>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <th style={cellStyle}>Serial No.</th>
            <th style={cellStyle}>Name</th>
            <th style={cellStyle}>Age</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <React.Fragment key={row.id}>
              {/* Arrow Indicator */}
              {row.hidden && (
                <tr>
                  <td
                    colSpan="3"
                    style={{
                      position: "relative",
                      textAlign: "center",
                    }}
                    onClick={() => showHiddenRow(row.id)} // Correctly restoring the hidden row
                  >
                    {/* ↑ Hidden Row Above (Click to Restore) ↑ */}
                    <ExpandCircleDownIcon className="showHiddenRowArrow" />
                  </td>
                </tr>
              )}
              {/* Row Data */}
              {!row.hidden && (
                <tr
                  onClick={() => handleRowInteraction(row.id)}
                  style={{
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  <td style={cellStyle}>{row.id}</td>
                  <td style={cellStyle}>{row.name}</td>
                  <td style={cellStyle}>{row.age}</td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const cellStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
};

export default HideAndShowRowDemo;

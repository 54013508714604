import React,{ useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makePostRequest } from "../../../utils/utils";


function Login() {

    const navigate = useNavigate();
    const [loginErrMsg, setLoginErrMsg] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const [upload, setUpload] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const initialValues = {
        email_id:'',
        password:'',
      };
      const validationSchema = Yup.object({
        email_id:Yup.string().email('Invalid email.').required('Required'),
        password:Yup.string().required('Required')
      });
    
    const onSubmit = async (values) => {

        console.log("Form Data", values);
        setShowLoader(true);
        setLoginErrMsg("");
      
        makePostRequest("superadmin/superadminlogin", values)
        .then((response) => {
          if (response.data.data) {
            console.log(response);
           
            if (response.data.data.user_type == "User") {
              setLoginErrMsg("Invalid User");
              navigate('/Login');
            } else {
              
              console.log(response.data.data);
              sessionStorage.setItem("accessToken", response.data.data.token_id);
              localStorage.setItem("userType", response.data.data.user_type);
              localStorage.setItem("userId", response.data.data.employee_id);
              localStorage.setItem("companyId", response.data.data.company_id);
              localStorage.setItem("adminProfilePic",response.data.data.profile_pic);
  
              
  
              // if (response.data.data.user_type != "SuperAdmin") {
              //   makePostRequest(
              //     "employee/updateemployeestatus",
              //     {
              //       employee_id: response.data.data.employee_id,
              //       fcmtoken: fcmtoken,
              //     },
              //     response.data.data.schemaname
              //   ).catch((err) => {
              //     console.log(err);
              //     setShowLoader(false);
              //   });
              // }
  
              setUpload(!upload);
              if (response.data.data.user_type == "SuperAdmin") {
                navigate('/EmployeeListing');
              } else {
                sessionStorage.setItem("schema", response.data.data.schemaname);
                localStorage.setItem("schema", response.data.data.schemaname);
                //sessionStorage.setItem("schema", 'public');
                sessionStorage.setItem("companyName",response.data.data.company_name);
                navigate('/EmployeeListing');
              }
            }
            setShowLoader(false);
          }
        })
        .catch((err) => {
          setLoginErrMsg("Invalid Username/Password");
          setShowLoader(false);
        });  
    }
    
    React.useEffect(() => {
        sessionStorage.clear();
        localStorage.clear();
      }, []);

  return (
    <div className="wrapper">
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <h1>Login</h1>
        </div>
        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>  
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Email"
                      placeholder="Email"
                      labelClass="myLable"
                      name="email_id"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Password"
                      placeholder="Password"
                      labelClass="myLable"
                      name="password"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button type="submit" className="logginBt">Login</Button>
                  </Grid>
                  
                </Grid>
              </Form>
            )}
          </Formik>
      </div>
      {showLoader ? (
        <div className="loaderNew">
        <div className="loader"></div>
      </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Login
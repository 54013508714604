import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const LoginEmployee = ({ length = 5 }) => {
  
  const navigate = useNavigate();

  // For PIN
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Only allow numeric input and limit to one character
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next field if it's not the last one
      if (index < length - 1) {
        inputsRef.current[index + 1].focus();
      }
    } else if (value === "") {
      // Move to previous field if value is deleted
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    // Set focus on the first input field when the component mounts
    inputsRef.current[0]?.focus();
  }, []);
  // End

  const initialValues = {
    first_name: "",
    enter_password: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    enter_password: Yup.string().required("Required"),
  });

  const onSubmit = (values) => console.log("Form Data", values);

  return (

    <div className="adminLoginMain">
      <div className="adminLoginTop">
        <h1>ToDoBoom</h1>
      </div>
      <div className="adminLoginBody">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <h1 className="loginDay">Monday, </h1>
                  <h2 className="loginDatTime">
                    08/20/2024 , <span>05:00 PM</span>
                  </h2>
                </Grid>
                <Grid item xs={12} md={12}>
                  <label className="myLable">Enter Your Pin</label>
                  <div className="myOtpMain">
                    {otp.map((value, index) => (
                      <input
                        placeholder="-"
                        key={index}
                        type="text"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        maxLength={1}
                        ref={(el) => (inputsRef.current[index] = el)}
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                          fontSize: "18px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      />
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    className="logginBt"
                    onClick={() => navigate("/EmployeeTaskList")}
                  >
                    OK
                  </Button>
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <p className="doNotHave">
                    Do not have an account? <span>Register</span>
                  </p>
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginEmployee;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import swal from "sweetalert";

import "react-medium-image-zoom/dist/styles.css";


import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import { makeGetRequest, makePostRequest } from "../../../utils/utils";


function EditLocation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  console.log(data);
  const [showLoader, setShowLoader] = React.useState(false);
  
  const [formValues, setFormValues] = React.useState(null);
 
  const [locname, setLocName] = React.useState("");

  const initialValues = {
    loc_name: locname,
  };

  const validationSchema = Yup.object({
    loc_name: Yup.string().trim().required("Required"),
    
  });

  const handleLocName = (e) => {
    if (e.target.value != "") {
      setLocName(e.target.value);
    }
  };

 

 

  const onSubmit = async (values) => {
    console.log("Form Data", values);
   

    let obj={
        'loc_id':parseInt(data),
        'loc_name':values.loc_name
    }
        await makePostRequest("location/updatelocation", obj)
          .then((response) => {
          
            if (response.data) {
              console.log(response);
            
              setShowLoader(false);
              setFormValues(null);
              localStorage.setItem("TabName", "Location");
              navigate("/AdminTasksList");
            }
          })
          .catch((err) => {
            swal("Warning", err.response.data.message, "warning", {
              timer: 3000,
              buttons: false,
            });
            setShowLoader(false);
          });
      } 
    

 const getLocationById=async(data)=>{

    console.log(data);

    await makeGetRequest("location/getlocationById/"+data)
    .then((response) => {
    
      if (response.data) {
        console.log(response.data.data);
        setLocName(response.data.data.loc_name)
      }
    })
    .catch((err) => {
      swal("Warning", err.response.data.message, "warning", {
        timer: 3000,
        buttons: false,
      });
      setShowLoader(false);
    });
 }
  

   React.useEffect(() => {
    localStorage.setItem("TabName", "Location");
    
    if (data) getLocationById(data);
    
  }, []);

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
          </div>
          <h1>Edit Location Name</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain forUpperBottomBt">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Location Name"
                      placeholder="Location Name"
                      labelClass="myLable"
                      name="loc_name"
                      className="myInput"
                      onBlur={(e) => handleLocName(e)}
                      isRequired="true"
                    />
                  </Grid>
                  
                    <Grid item xs={12} md={12}>
                      <div className="upperBottomBt">
                        <Button type="submit" className="logginBt">
                          Update
                        </Button>
                      </div>
                    </Grid>
                  
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <AdminBottomNav />
      </div>
    </>
  );

}
export default EditLocation;

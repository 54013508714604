import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import { Grid, Button } from "@mui/material";
import Slider from "react-slick";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import moment from "moment/moment";
import SettingsVoiceOutlinedIcon from "@mui/icons-material/SettingsVoiceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";
import userWithCheck from "../../../assets/images/userWithCheck.svg";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";

import PropTypes, { func } from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AppDrawer from "../../AppDrawer";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import pencil from "../../../assets/images/pencil.svg";
import availabilityPreview from "../../../assets/images/availabilityPreview.svg";
import Switch from "@mui/material/Switch";
import avarPi from "../../../assets/images/avtarPic.png";
import speak_animation from "../../../assets/images/speak_animation.gif";
import { DateRange } from "@mui/icons-material";
import TopBar from "../TopBar";
import FormControlLabel from "@mui/material/FormControlLabel";
import { set } from "lodash";



function Location() {

// For Swipe Left Right
const [swipeDirection, setSwipeDirection] = useState(null);
const [tempSlide, setTempSlide] = React.useState("");
const startXRef = useRef(null);
const threshold = 50;

const handleTouchStart = (e, index) => {
  startXRef.current = e.touches[0].clientX;
};

const swipeNSelection = (event) => {
  //forSelectList(event);
  resetSwipeDirection(event);
};





const handleTouchMove = (e, index) => {
  const currentX = e.touches[0].clientX;
  const distance = currentX - startXRef.current;

  if (Math.abs(distance) > threshold) {
    if (distance > 0) {
      var element = document.getElementById("bugfree-" + tempSlide);
      if (element != null) {
        element.classList.remove("swiped-right");
        element.classList.remove("swiped-left");
      }

      var elementnew = document.getElementById("bugfree-" + index);
      elementnew.classList.toggle("swiped-right");
      setTempSlide(index);
      //setSwipeDirection("right");
    } else {
      //setSwipeDirection("left");
      var element = document.getElementById("bugfree-" + tempSlide);
      if (element != null) {
        element.classList.remove("swiped-left");
        element.classList.remove("swiped-right");
      }

      var elementnew = document.getElementById("bugfree-" + index);
      elementnew.classList.toggle("swiped-left");
      setTempSlide(index);
    }
  }
};

const handleTouchMove2 = (e) => {
  const currentX = e.touches[0].clientX;
  const distance = currentX - startXRef.current;

  if (Math.abs(distance) > threshold) {
    if (distance > 0) {
      setSwipeDirection("right");
    } else {
      setSwipeDirection("left");
    }
  }
};

const handleTouchEnd = () => {
  var element = document.getElementById("bugfree-" + tempSlide);
  if (element != null) {
    element.classList.remove("swiped-right");
    element.classList.remove("swiped-left");
  }
  // Optionally, you could reset the swipeDirection here if you want to clear it after some time
  // setSwipeDirection(null);
};

const resetSwipeDirection = () => {
  setSwipeDirection(null);
};
// Swipe Left Right End


    return (
        <>
          <TopBar />
          <div className="wrapper">
            <div className="taskListAdmMain forUpperBottomBt">
               {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            
            <h1>Manage Locations</h1>
            
          </div>
          {/* TopBarEnd */}       
                  
          </div>
          </div>
       </>
    )       
}
          
  
  export default Location;
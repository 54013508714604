import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function Textarea(props) {
  const { label, name, labelClass, isRequired, ...rest } = props;
  return (
    <div className="form-control">
      <label htmlFor={name} className={labelClass}>
        {label}
        {isRequired == "true" ? <span className="requiredStar">*</span> : <></>}
      </label>
      <Field as="textarea" id={name} name={name} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Textarea;

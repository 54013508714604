import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import FilterIcon from "@mui/icons-material/Filter";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Editor } from "primereact/editor";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import Switch from "@mui/material/Switch";
import { makeGetRequest } from "../../../utils/utils";

function CategoryDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};
  const [showLoader, setShowLoader] = React.useState(false);
  const [categoryDescription, setCategoryDescription] = useState([]);
  const [CategoryIds, setCategoryIds] = useState([]);
  const [upload, setUpload] = React.useState(false);

  const [checkboxOptions, setcheckboxOptions] = React.useState([]);

  const getDetails = (Id) => {
    if (Id) {
      setShowLoader(true);
      makeGetRequest("category/getCategoryById/" + Id)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
            setShowLoader(false);
            setCategoryDescription(response.data.data[0]);
            setCategoryIds(response.data.data[0].frequancy_ids);
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          setShowLoader(false);
        });
    }
  };

  async function getfrequancylist() {
    setShowLoader(true);
    await makeGetRequest("frequancy/getfrequancylist/frequancy_name/asc/null/")
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            //console.log("drop res", response.data.data.res);
            setcheckboxOptions(response.data.data.res);
          } else setcheckboxOptions(null);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const getPresentIds = (ids, objects) => {
    return objects
      .filter((obj) => ids.includes(obj.frequancyid)) // Filter objects by ID
      .map((obj) => obj.frequancy_name);
  };

  const CategorySelectList = getPresentIds(CategoryIds, checkboxOptions);

  useEffect(() => {
    if (Id) getDetails(Id);
    getfrequancylist();
  }, []);

  return (
    <>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Task Group Details</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h1 className="tskHeading">{categoryDescription.category_name}</h1>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <h2 className="tskDisc">{categoryDescription.category_name}</h2>
            </Grid> */}
            <Grid item xs={12} md={12}>
              {CategorySelectList.length > 0 ? (
                <>
                  {CategorySelectList.map((CatList) => (
                    <div className="tskBuletPoints" key={CatList}>
                      <div className="tskBuletPointsInr">
                        <em></em> <span>{CatList}</span>
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CategoryDetails;

import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import VerifiedIcon from "@mui/icons-material/Verified";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment/moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import underContruction from "../../../assets/images/under-construction.gif";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FloatButton from "../../FloatButton";
import AppDrawer from "../../AppDrawer";
import noPic from "../../../assets/images/no-data.png";
import unlock from "../../../assets/images/unlock.png";
import lock from "../../../assets/images/lock.png";
import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import avatarPic from "../../../assets/images/avtarPic.png";
import avtarp from "../../../assets/images/avtarPic.png";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Switch from "@mui/material/Switch";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Person2Icon from "@mui/icons-material/Person2";
import Zoom from "react-medium-image-zoom";
import TopBar from "../TopBar";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ENd

function getDatesArray(startDate, endDate) {
  const dates = [];
  const start = moment(startDate);
  const end = moment(endDate);

  while (start.isSameOrBefore(end)) {
    dates.push({
      date: start.format("YYYY-MM-DD"),
      day: start.format("ddd"),
      formatdate: start.format("DD"),
    });
    start.add(1, "day");
  }

  return dates;
}

function ReportAdmin() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shiftTimes, setShiftTimes] =useState([])
  const [selectedShiftTime, setSelectedShiftTime] = useState('');
  const [selectedLocationShiftTime, setSelectedLocationShiftTime] = useState('');
  const [reload, setReload] = useState(false);

  function SelectLocationSliders(idx) {
    setCurrentIndex(idx);
    let shiftlocation;
    let shiftselectedtimes;

    if(selectedShiftTime=='')
    {
      setSelectedLocationShiftTime(shiftTimes[0].unique_shift_time)
      setSelectedShiftTime(shiftTimes[0].unique_shift_time)
      shiftlocation = shiftTimes[0].unique_shift_time;
      shiftselectedtimes = shiftTimes[0].unique_shift_time;
    }
    else
    {
      setSelectedLocationShiftTime(selectedShiftTime)
      setSelectedShiftTime(selectedShiftTime)
      shiftlocation = selectedShiftTime;
      shiftselectedtimes = selectedShiftTime;
    }

    
    let temp;
    if (idx > 0) temp = idx - 1;
    else temp = idx;
    if (idx == 0) {
      statusChangeFun(localStorage.getItem("categoryrsetid"), shiftselectedtimes);
    } else {
      setLocationDefault(loc[temp].location);
      statusSliderChangeFun(categoryid, loc[temp].location, shiftselectedtimes);
    }
  }

  const settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  // const settings2 = useCallback(() => {
  //   return {
  //     dots: true,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //   };
  // }, [reload]);


  const handleReload = () => {
    setReload(reload + 1);
  };

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [selected, setSelected] = React.useState(false);
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [sortBy, setSortBy] = React.useState("asc");
  const [upload, setUpload] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [categoryid, setCategoryID] = React.useState([]);
  const [categoryname, setCategoryName] = React.useState([]);
  const [frequancyid, setFrequancyID] = React.useState([]);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [slider1, setSlider1] = useState(null);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [tempSlide, setTempSlide] = React.useState("");
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment("2024-11-01"));
  const [currentTime, setCurrentTime] = React.useState(
    moment(todaysDate.time).format("HH:mm")
  );
  const [startADate, setStartADate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );

  const [reportDateToday, setReportDateToday] = React.useState(
    moment(todaysDate.time).format("MM-DD-YYYY")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("year").add(1, "year")
  );
  const [datesArray, setDatesArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [UserId, setUserId] = useState();
  const [weekDay, setWeekDay] = useState(moment().format("dddd"));
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [totalOutOfTask, setTotalOutOfTask] = useState(0);
  const [completeIteration, setCompleteIteration] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(10);
  const [avaialableEmployee, setAvaialableEmployee] = useState([]);
  const [availablecnt, setAvailableCnt] = useState(0);
  const [schedulefrequancylist, setScheduleFrequancyList] = React.useState([]);
  const [schedulelist, setScheduleList] = React.useState([]);
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [loc, setLoc] = useState([]);
  const [locationDefault, setLocationDefault] = React.useState("");

  const [summaryList, setSummaryList] = React.useState([]);

  let percentage = 0;
  const initialValues = [100];
  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
  }

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (taskObj) => {
    console.log(taskObj);
    if (taskObj.assigntask == null) {
      swal("This Task is not Assigned to anyone ", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    } else {
      if (
        (taskObj.assigntask != null &&
          taskObj.assigntask[0].before_img !== null) ||
        taskObj.assigntask[0].after_img !== null
      ) {
        setImageUploadBefore(taskObj.assigntask[0].before_img);
        setImageUploadAfter(taskObj.assigntask[0].after_img);
        setOpen(true);
      } else {
        swal("No Image Uploaded", "Information", "info", {
          timer: 3000,
          buttons: false,
        });
        setOpen(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  function getArrowDatesArray(startDate, endDate) {
    const dates = [];
    const start = moment(startDate);
    const end = moment(endDate);

    while (start.isSameOrBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "day");
    }

    //console.log("new dates===>"+JSON.stringify(dates))

    return dates;
  }

  const datesArrayVal = getDatesArray(startDate, endDate);
  const today = moment();
  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(today, "day")
  );

  const settings = {
    initialSlide: todayIndex,
    // className: "center",
    // centerMode: false,
    // infinite: true,
    // centerPadding: "0px",
    // slidesToShow: 3,
    // speed: 500,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,

    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    //autoplay: true,
    // nextArrow: <NextArrow />,
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  function getSelectedWeek(startDate) {
    let week = [];
    var newdates = new Date(startDate);
    var d = new Date(startDate);
    d.setDate(1);
    var curr = getMonday(newdates);
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    setCurrentWeekCal(week);
  }

  function SelectSliders(idx, obs) {
    setStartADate(obs);
    const date = moment(obs);
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    slider1?.slickGoTo(idx);
  
    if (currentIndex == 0)
      {
        ChangeDateSummary(categoryid, obs, dayName);
      } 
        
      //ChangeDateSummary(categoryid, obs, dayName);
    else 
    {
      ChangeDateSummary(categoryid, obs, dayName);
    }
    
    //ChangeDateFunxxxx(categoryid, obs, dayName);
  }



  const handleDivClick = (event, idx, obs) => {
    event.stopPropagation(); // Add this line
    event.preventDefault();
    setCurrentIndex(0);
    handleReload();
    SelectSliders(idx, obs); // Call your function
  };

  const handleSliderChange = (idx, obs) => {
    setCurrentIndex(0);
    handleReload();
    SelectSliders(idx, obs); // Call your function
  };


  const changeList = (e, id, catname) => {
    //setShowLoader(true);
    setCategoryID(id);
    setCategoryName(catname);
    // const obsdate = moment(todaysDate.time).format("YYYY-MM-DD");
    // setStartADate(moment(todaysDate.time).format("YYYY-MM-DD"));
    // const date = moment(moment(todaysDate.time).format("YYYY-MM-DD"));
    // const dayName = date.format("dddd");
    // setWeekDay(dayName);
    // ChangeDateFun(id, obsdate, dayName);
    ChangeDateFun(id, startADate, weekDay);
  };



  async function activeGetDateShiftTimesFrequencies(shiftime,location, catid, searchdate, day) {
    
    let templatenametemp = localStorage.getItem("active_template")
    let userids = localStorage.getItem("userId");

    await makeGetRequest("schedular/GetAllShiftTimesFrequency/'" +templatenametemp+"'/'"+weekDay+"'/'"+shiftime+"'/'"+location+"'")
      .then((response) => {
        if (response.data.data.rows.length) 
          {

            let fetchedfreqid = parseInt(response.data.data.rows[0].frequencyid);


//"employeeschedular/employeereporttask/"

  makeGetRequest("employeeschedular/employeeloginreporttask/"+userids+"/'"+searchdate+"'/"+catid+"/'"+day+"'/'"+location+"'/"+fetchedfreqid+"/'"+shiftime+"'/"+sortColumnName+"/"+sortBy+"/"+null+"")
              .then((response) => {
                console.log(response.data.data.rows);
                if (response.data.data.res.rows) {
                  //alert(response.data.data.rows[0].categories.length);
                  if (response.data.data.res.rows.length > 0) {
                    //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
                    setEmployeeTaskList(response.data.data.res.rows);
                    setTotalOutOfTask(response.data.data.res.rows.length);
                    setCompleteCount(
                      response.data.data.rescounts.rows[0].total_count
                    );
                    //let totalTask = response.data.data.res.rowCount;
                    let totalTask = response.data.data.res.rows[0].total_count_without_search;
                    let completedTask =
                      response.data.data.rescounts.rows[0].total_count;
                    let Total = (completedTask * 100) / totalTask;
                    let FinalTotal = Math.round(Total);
                    setCompletePercentage(FinalTotal);
                    const iterations = FinalTotal / 10;
                    setCompleteIteration(iterations);
                    getCompletedTaskCount(catid, searchdate, location, shiftime);
                  } else {
                    setCompleteCount(0)
                    setEmployeeTaskList([]);
                    setAvaialableEmployee([]);
                    setAvailableCnt(0);
                    setCompletePercentage(0);
                    const iterations = 0;
                    setCompleteIteration(iterations);
                    setTotalOutOfTask(0)
                    getCompletedTaskCount(catid, searchdate, location, shiftime);
                    setShowLoader(false);
                  }
                  setUpload(!upload);
                }
              })
              .catch((err) => {
                //setShowLoader(false);
              });



          }
          else
          {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              setTotalOutOfTask(0)
              const iterations = 0;
              setShowLoader(false);
          }
        }).catch((err) => {
          console.log(err);
        })

}


  async function activeGetAllShiftTimesFrequencies(shiftime,location, catid, searchString) {
    
    let templatenametemp = localStorage.getItem("active_template")
    let userids = localStorage.getItem("userId");

    await makeGetRequest("schedular/GetAllShiftTimesFrequency/'" +templatenametemp+"'/'"+weekDay+"'/'"+shiftime+"'/'"+location+"'")
      .then((response) => {
        if (response.data.data.rows.length) 
          {

            let fetchedfreqid = parseInt(response.data.data.rows[0].frequencyid);

//"employeeschedular/employeereporttask/"

if (!searchString) {
  searchString = null;
  setSearchText(null);
} else {
  setSearchText(searchString);
}

  makeGetRequest("employeeschedular/employeeloginreporttask/"+userids+"/'"+startADate+"'/"+catid+"/'"+weekDay+"'/'"+location+"'/"+fetchedfreqid+"/'"+shiftime+"'/"+sortColumnName+"/"+sortBy+"/"+searchString)
              .then((response) => {
                console.log(response.data.data.rows);
                if (response.data.data.res.rows) {
                  //alert(response.data.data.rows[0].categories.length);
                  if (response.data.data.res.rows.length > 0) {
                    //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
                    setEmployeeTaskList(response.data.data.res.rows);
                    if(searchString==null)
                    {
                      setTotalOutOfTask(response.data.data.res.rows.length);
                    }
                    
                    setCompleteCount(
                      response.data.data.rescounts.rows[0].total_count
                    );
                    //let totalTask = response.data.data.res.rowCount;
                    let totalTask = response.data.data.res.rows[0].total_count_without_search;
                    let completedTask =
                      response.data.data.rescounts.rows[0].total_count;
                    let Total = (completedTask * 100) / totalTask;
                    let FinalTotal = Math.round(Total);
                    setCompletePercentage(FinalTotal);
                    const iterations = FinalTotal / 10;
                    setCompleteIteration(iterations);
                    getCompletedTaskCount(catid, startADate, location, shiftime);
                  } else {
                    setEmployeeTaskList([]);
                    setAvaialableEmployee([]);
                    setAvailableCnt(0);
                    setCompletePercentage(0);
                    setCompleteCount(0)
                    const iterations = 0;
                    setCompleteIteration(iterations);
                    setTotalOutOfTask(0)
                    getCompletedTaskCount(catid, startADate, location, shiftime);
                    setShowLoader(false);
                  }
                  setUpload(!upload);
                }
              })
              .catch((err) => {
                //setShowLoader(false);
              });



          }
          else
          {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              setTotalOutOfTask(0)
              const iterations = 0;
              setShowLoader(false);
          }
        }).catch((err) => {
          console.log(err);
        })

}

  

  const getSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      //alert("111111")

      let searchcat = localStorage.getItem("categoryrsetid");

      activeGetAllShiftTimesFrequencies(selectedShiftTime, locationDefault, parseInt(searchcat), searchString);
      return false;


      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString +
          "/'" +
          locationDefault +
          "'/'"+
          selectedShiftTime+
          "'"
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              setTotalOutOfTask(response.data.data.res.rows.length);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              //setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function getCompletedTaskCount(catid, startdate, location, shiftimes) {
    
    await makeGetRequest(
      "employeeschedular/selectreporttask/" +
        catid +
        "/" +
        startdate +
        "/'" +
        location +
        "'/'"+
        shiftimes+"'"
    )
      .then((response) => {
        console.log("My available employee list==>" + JSON.stringify(response));
        if (response.data.data.rows) {
          if (response.data.data.rows.length > 0) {
            setAvaialableEmployee(response.data.data.rows);
            setAvailableCnt(response.data.data.rowCount);
            setShowLoader(false);
          } else {
            setAvaialableEmployee([]);
            setAvailableCnt(0);
            setShowLoader(false);
          }

          setUpload(!upload);
        }
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  const statusSliderChangeFun = async (catid, location, shifttimes) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      activeGetAllShiftTimesFrequencies(selectedShiftTime, location, catid, null);
      return false;

      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          catid +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null +
          "/'" +
          location+ 
          "'/'"+
          selectedShiftTime+
          "'"
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
              setEmployeeTaskList(response.data.data.res.rows);
              setTotalOutOfTask(response.data.data.res.rows.length);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, startADate, location, shifttimes);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
      //setShowLoader(false);
    }
  };


  const statusDateChangeClick = async (catid, shiftime, searchdate, searchday)=>{

    let userids = localStorage.getItem("userId");
    setDropDownSelect(catid);
    if (catid > 0) {
      await makeGetRequest(
        "employeeschedular/employeesummaryreporttask/" +
          userids +
          "/'" +
          searchdate +
          "'/" +
          catid +
          "/'" +
          searchday +
          "'/'" +
          localStorage.getItem("active_template") +
          "'/'"+shiftime+"'"
      )
        .then((response) => {
          console.log("my new query result===>" + JSON.stringify(response));
          if (response.data.data.rows) {
            if (response.data.data.rows.length > 0) {
              setSummaryList(response.data.data.rows);
              setShowLoader(false);
            } else {
              setSummaryList([]);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }

  }

  const statusChangeFun = async (catid, fetchshiftime) => {
    
    localStorage.setItem("categoryrsetid", catid);
    let userids = localStorage.getItem("userId");
    setDropDownSelect(catid);
    if (catid > 0) {
      await makeGetRequest(
        "employeeschedular/employeesummaryreporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          catid +
          "/'" +
          weekDay +
          "'/'" +
          localStorage.getItem("active_template") +
          "'/'"+fetchshiftime+"'"
      )
        .then((response) => {
          console.log("my new query result===>" + JSON.stringify(response));
          if (response.data.data.rows) {
            if (response.data.data.rows.length > 0) {
              setSummaryList(response.data.data.rows);
              setShowLoader(false);
            } else {
              setSummaryList([]);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }
  };

  const ChangeDateFun = async (catid, searchdate, searchday) => {
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    getAllScheduleList();
  };

  const ChangeDateSummary = async (catid, searchdate, searchday) => {
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    
    getAllDateScheduleList(catid, searchdate, searchday)
    // let userids = localStorage.getItem("userId");
    // if (catid > 0) {
    //   await makeGetRequest(
    //     "employeeschedular/employeesummaryreporttask/" +
    //       userids +
    //       "/'" +
    //       searchdate +
    //       "'/" +
    //       catid +
    //       "/'" +
    //       searchday +
    //       "'/'" +
    //       localStorage.getItem("active_template") +
    //       "'"
    //   )
    //     .then((response) => {
    //       console.log("my new query result===>" + JSON.stringify(response));
    //       if (response.data.data.rows) {
    //         if (response.data.data.rows.length > 0) {
    //           setSummaryList(response.data.data.rows);
    //           setShowLoader(false);
    //         } else {
    //           setSummaryList([]);
    //         }
    //         setUpload(!upload);
    //       }
    //     })
    //     .catch((err) => {
    //       setShowLoader(false);
    //     });
    // }
  };

  const ChangeDateFunxxxx = async (catid, searchdate, searchday) => {
    //if (!searchString) searchString = null;

    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      
      activeGetDateShiftTimesFrequencies(selectedLocationShiftTime, locationDefault, catid, searchdate, searchday);
      return false;

      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          searchdate +
          "'/" +
          catid +
          "/'" +
          searchday +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null +
          "/'" +
          locationDefault + 
          "'/'"+
          selectedShiftTime+
          "'"      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar", response.data.data.res.rows[0].assigntask[0].after_img)
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].before_img)

              setEmployeeTaskList(response.data.data.res.rows);
              setTotalOutOfTask(response.data.data.res.rows.length);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid, searchdate, locationDefault);
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  async function getAllCategoryList(searchString, location, fetchshiftime) {
    if (!searchString) searchString = null;
    //setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);

            setCategoryID(response.data.data.res[0].categoryid);
            setCategoryName(response.data.data.res[0].category_name);
            setFrequancyID(response.data.data.res[0].frequancy_ids);

            const datesArray = getDatesArray(startDate, endDate);
            setMondaysCal(datesArray);
            
            statusChangeFun(response.data.data.res[0].categoryid, fetchshiftime);
            //setShowLoader(false);
          } else {
            setCategoryList([]);
            //setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  const [checked, setChecked] = React.useState(false);
  const handleSwitchCheck = (event) => {
    if (event.target.checked == true) {
      setChecked(event.target.checked);
      navigate("/EmployeeTaskListNew");
    } else {
      navigate("/ReportAdmin");
    }
  };


  async function activeGetAllShiftTimes(templatename, selectedDay) {
  
    
    await makeGetRequest("schedular/GetAllShiftTimes/" +templatename+"/"+selectedDay)
      .then((response) => {
        if (response.data.data.rows.length>0) 
          {
            //alert(JSON.stringify(response.data.data.rows[0].unique_shift_time))
            setShiftTimes(response.data.data.rows)
            setSelectedShiftTime(response.data.data.rows[0].unique_shift_time)
            activelocationList(selectedDay, response.data.data.rows[0].unique_shift_time);
          }
          else
          {
            setShiftTimes()
            setSelectedShiftTime()
            getAllCategoryList(null, '', '')
            //setShowLoader(false);
          }
        }).catch((err) => {
          console.log(err);
        })

}


async function activeGetAllDateShiftTimes(templatename, catid, searchdate, selectedDay) {
  
    
  await makeGetRequest("schedular/GetAllShiftTimes/" +templatename+"/"+selectedDay)
    .then((response) => {
      if (response.data.data.rows) 
        {
          //alert(JSON.stringify(response.data.data.rows[0].unique_shift_time))
          setShiftTimes(response.data.data.rows)
          setSelectedShiftTime(response.data.data.rows[0].unique_shift_time)
          activeDatelocationList(selectedDay, response.data.data.rows[0].unique_shift_time,catid, searchdate);
        }
      }).catch((err) => {
        console.log(err);
      })

}

const handleRadioButtonChange = (event) => {
  setSelectedShiftTime(event.target.value);
  let catid = localStorage.getItem("categoryrsetid")
  statusChangeFun(catid, event.target.value);
};


const handleLocationRadioButtonChange = (event, location) => {
  setSelectedLocationShiftTime(event.target.value);
  setSelectedShiftTime(event.target.value)
  let catid = localStorage.getItem("categoryrsetid")
  activeGetAllShiftTimesFrequencies(event.target.value, location, catid, null);
  return false;

  getCompletedTaskCount(parseInt(catid), startADate, location, event.target.value);
  
};



async function activeDatelocationList(day, shiftime, catid, searchdate) {
  let bodyFormData = {
    weekday: day,
    template_name: localStorage.getItem("active_template"),
  };
  await makeGetRequest(
    "schedular/GetActiveTemplateLocations/" +
      localStorage.getItem("active_template")
  )
    .then((response) => {
      if (response.data.data) {
        setLoc(response.data.data.rows);
        setLocationDefault(response.data.data.rows[0].location);

        
        statusDateChangeClick(catid,shiftime, searchdate, day);
        
      } else {
        setLoc([]);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}


  async function activelocationList(day, shiftime) {
    let bodyFormData = {
      weekday: day,
      template_name: localStorage.getItem("active_template"),
    };
    await makeGetRequest(
      "schedular/GetActiveTemplateLocations/" +
        localStorage.getItem("active_template")
    )
      .then((response) => {
        if (response.data.data) {
          setLoc(response.data.data.rows);
          setLocationDefault(response.data.data.rows[0].location);

          if (
            categoryid == null ||
            categoryid == "" ||
            categoryid.length == 0
          ) {
            getAllCategoryList(null, response.data.data.rows[0].location, shiftime);
          } else {
            statusChangeFun(localStorage.getItem("categoryrsetid"),shiftime);
          }
        } else {
          setLoc([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAllScheduleList() {
    setShowLoader(true);
    await makeGetRequest(
      "schedular/getTemplateListForSummaryCount/" +
        parseInt(localStorage.getItem("companyId")) +
        "/false"
    )
      .then(async (response) => {
        if (response.data.data.length > 0) {
          console.log("schedule", response.data.data);
          if (response.data.data.length > 0) {
            setScheduleFrequancyList(response.data.data);
            let specificRecord;
            specificRecord = response.data.data.find(
              (item) => item.is_active === true
            );
            
            setScheduleList(specificRecord);

            localStorage.setItem(
              "active_template",
              specificRecord.template_name
            );


            activeGetAllShiftTimes(specificRecord.template_name, weekDay)

          } else setScheduleList([]);
        } else {
          setShowLoader(false);
          swal("", "Please add template first", "info");
          setScheduleList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }


  async function getAllDateScheduleList(catid, searchdate, searchday) {
    setShowLoader(true);
    await makeGetRequest(
      "schedular/getTemplateListForSummaryCount/" +
        parseInt(localStorage.getItem("companyId")) +
        "/false"
    )
      .then(async (response) => {
        if (response.data.data.length > 0) {
          console.log("schedule", response.data.data);
          if (response.data.data.length > 0) {
            setScheduleFrequancyList(response.data.data);
            let specificRecord;
            specificRecord = response.data.data.find(
              (item) => item.is_active === true
            );
            
            setScheduleList(specificRecord);

            localStorage.setItem(
              "active_template",
              specificRecord.template_name
            );


            activeGetAllDateShiftTimes(specificRecord.template_name, catid, searchdate, searchday)

          } else setScheduleList([]);
        } else {
          setShowLoader(false);
          swal("", "Please add template first", "info");
          setScheduleList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    if (categoryid == null || categoryid == "" || categoryid.length == 0) {
      //getAllCategoryList(null);
      getAllScheduleList();
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");
    }
  }, []);
  return (
    <>
      <TopBar />
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            {/* <AppDrawer /> */}
            {/* <NavigateBeforeIcon onClick={() => navigate("/")} /> */}
          </div>
          <h1>
            <marquee>Reports</marquee>
          </h1>

          <div className="switchAccount profile-switcher">
            <div
              className="acount1 actPro"
              onClick={() => navigate("/ReportAdmin")}
            >
              <AdminPanelSettingsIcon />
            </div>
            <Switch checked={checked} onChange={handleSwitchCheck} {...label} />
            <div
              className="acount1"
              onClick={() => navigate("/EmployeeTaskListNew")}
            >
              <Person2Icon />
            </div>
          </div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain reportAdminMain">
          <div
            className={`mySlik clickAllTile forReportDateSelection ${
              scrolling ? "scrolledDate22" : ""
            }`}
          >
            <div className="slider-container">
              {/* {JSON.stringify(mondaysCal)} */}
              <Slider
                className="dateSliderOuter"
                {...settings}
                ref={(slider) => setSlider1(slider)}
                afterChange={(index) =>
                  handleSliderChange(index, mondaysCal[index].date)
                }
              >
                {mondaysCal.map((obs, idx) => (
                  <>
                    <div
                      className="dateSliderInner"
                      key={idx}
                      onClick={(event) => handleDivClick(event, idx, obs.date)}
                    >
                      <span>{obs.day}</span>
                      <h3>{obs.formatdate}</h3>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
          <div className="tabsMainD forReportNewTabs">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {categoryList ? (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons={false}
                    className={`kkk ${scrolling ? "scrolledTab" : ""}`}
                  >
                    {categoryList.map((catObj, i) => (
                      <Tab
                        label={catObj.category_name}
                        onClick={(e) =>
                          changeList(e, catObj.categoryid, catObj.category_name)
                        }
                      />
                    ))}
                  </Tabs>
                ) : (
                  <></>
                )}
              </Box>
              {categoryList.map((catObj, i) => (
                <CustomTabPanel value={value} index={i}>
                  <div className="tabInrContDiv">
                    {/* Commented By Kailas */}
                    <div className="daySlider emplShifSlider checkListSlidMain repoSllider">
                      <Slider
                        ref={sliderRef}
                        key={reload}
                        {...settings2}
                        afterChange={(ins) => SelectLocationSliders(ins)}
                      >
                        
                        <div>
                          <h3 className="slidDayHeading newSLideDayHeading">
                            <b>
                              Summary Report for {catObj.category_name} On{" "}
                              <p className="repoDate">
                                ({moment(startADate).format("MM-DD-YYYY")})
                              </p>
                            </b>
                          </h3>

                          <label className="myLable" style={{textAlign:'left'}}>
                            Select Shift
                          </label>
                          <div className="openDropMain" style={{textAlign:'left',marginBottom: 15}}>
                            <div className="openDropInr columnView">
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="select1"
                                name="radio-buttons-group"
                              >
                                {shiftTimes && shiftTimes.map((shiftTime, index) => (<>
                                <FormControlLabel value={shiftTime.unique_shift_time}
                                checked={selectedShiftTime === shiftTime.unique_shift_time}
                                control={<Radio />} label={shiftTime.unique_shift_time} 
                                onChange={handleRadioButtonChange}
                                />
                                </>
                                ))}
                              </RadioGroup>
                            </FormControl>
                            </div>
                          </div>
                          <div className="sumryMain">
                            
                            {summaryList &&
                              summaryList.map((summaryobj, index) => (
                                <div className="sumryInrDi">
                                  <h2 className="inerSumryHeading">
                                    {summaryobj.location}
                                  </h2>
                                  {summaryobj.summary !== null ? (
                                    <div class="repViw">
                                      {summaryobj.summary !== null &&
                                        summaryobj.summary.map(
                                          (availableempobj, index) => (
                                            <p>
                                              {availableempobj.completed_tasks ==
                                              0 ? (
                                                <em className="repViwColorRed"></em>
                                              ) : (availableempobj.completed_tasks /
                                                  parseInt(
                                                    summaryobj.taskcount.count
                                                  )) *
                                                  100 <
                                                50 ? (
                                                <em className="repViwColorYellow"></em>
                                              ) : (
                                                <em className="repViwColorGreen"></em>
                                              )}

                                              <span>
                                                {availableempobj.full_name
                                                  .trim()
                                                  .includes(" ")
                                                  ? availableempobj.full_name.split(
                                                      " "
                                                    )[0] +
                                                    " " +
                                                    availableempobj.full_name.split(
                                                      " "
                                                    )[1][0]
                                                  : availableempobj.full_name}{" "}
                                                <b class="repViewCount">
                                                  {summaryobj.taskcount.count >
                                                  0 ? (
                                                    <>
                                                      {
                                                        (percentage =
                                                          Math.round(
                                                            (parseInt(
                                                              availableempobj.completed_tasks
                                                            ) /
                                                              parseInt(
                                                                summaryobj
                                                                  .taskcount
                                                                  .count
                                                              )) *
                                                              100
                                                          ))
                                                      }
                                                    </>
                                                  ) : (
                                                    <>0</>
                                                  )}
                                                  % (
                                                  {parseInt(
                                                    availableempobj.completed_tasks
                                                  )}{" "}
                                                  /{" "}
                                                  {parseInt(
                                                    summaryobj.taskcount.count
                                                  )}
                                                  )
                                                </b>
                                              </span>
                                            </p>
                                          )
                                        )}
                                    </div>
                                  ) : (
                                    <div className="templateError" style={{marginBottom:10}}>
                                      Employees are not available for{" "}
                                      {summaryobj.location} location
                                    </div>
                                  )}

                                  {Math.round(
                                    (parseInt(summaryobj.totalcompleted.count) /
                                      parseInt(summaryobj.taskcount.count)) *
                                      100
                                  ) > 0 ? (
                                    <div className="progBarMain">
                                      <div className="progBarInr">
                                        <section>
                                          {Math.round(
                                            (parseInt(
                                              summaryobj.totalcompleted.count
                                            ) /
                                              parseInt(
                                                summaryobj.taskcount.count
                                              )) *
                                              100
                                          ) > 0 ? (
                                            <ol class="progress-bar">
                                              {Array.from(
                                                { length: 10 },
                                                (_, i) =>
                                                  Math.round(
                                                    (parseInt(
                                                      summaryobj.totalcompleted
                                                        .count
                                                    ) /
                                                      parseInt(
                                                        summaryobj.taskcount
                                                          .count
                                                      )) *
                                                      100
                                                  ) >=
                                                    10 * (i + 1) &&
                                                  Math.round(
                                                    (parseInt(
                                                      summaryobj.totalcompleted
                                                        .count
                                                    ) /
                                                      parseInt(
                                                        summaryobj.taskcount
                                                          .count
                                                      )) *
                                                      100
                                                  ) >=
                                                    10 * (i + 2) ? (
                                                    <li
                                                      key={i}
                                                      class="is-complete"
                                                    ></li>
                                                  ) : Math.round(
                                                      (parseInt(
                                                        summaryobj
                                                          .totalcompleted.count
                                                      ) /
                                                        parseInt(
                                                          summaryobj.taskcount
                                                            .count
                                                        )) *
                                                        100
                                                    ) >=
                                                      10 * (i + 1) &&
                                                    Math.round(
                                                      (parseInt(
                                                        summaryobj
                                                          .totalcompleted.count
                                                      ) /
                                                        parseInt(
                                                          summaryobj.taskcount
                                                            .count
                                                        )) *
                                                        100
                                                    ) <=
                                                      10 * (i + 2) ? (
                                                    <li
                                                      key={i}
                                                      class="is-active"
                                                    >
                                                      {Math.round(
                                                        (parseInt(
                                                          summaryobj
                                                            .totalcompleted
                                                            .count
                                                        ) /
                                                          parseInt(
                                                            summaryobj.taskcount
                                                              .count
                                                          )) *
                                                          100
                                                      )}
                                                      %
                                                    </li>
                                                  ) : (
                                                    <li key={i}></li>
                                                  )
                                              )}
                                            </ol>
                                          ) : null}
                                        </section>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="progBarMain">
                                <div className="progBarInr">
                                  <section>

                                <ol class="progress-bar">
                                      <li class="is-complete is-active">
                                        <span>0%</span>
                                      </li>
                                      <li>
                                        <span>10%</span>
                                      </li>
                                      <li>
                                        <span>20%</span>
                                      </li>
                                      <li >
                                        <span>30%</span>
                                      </li>
                                      <li>
                                        <span>40%</span>
                                      </li>
                                      <li>
                                        <span>50%</span>
                                      </li>
                                      <li >
                                        <span>60%</span>
                                      </li>
                                      <li >
                                        <span>70%</span>
                                      </li>
                                      <li>
                                        <span>80%</span>
                                      </li>
                                      <li>
                                        <span>90%</span>
                                      </li>
                                      <li>
                                        <span>100%</span>
                                      </li>
                                    </ol>

                                  </section>
                                </div>
                              </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                        {loc.length > 0 &&
                          loc.map((locobj, index) => (
                            <div>
                              <h3 className="slidDayHeading newSLideDayHeading">
                                <b>{locobj.location}</b>
                              </h3>
                              <label className="myLable" style={{textAlign:'left'}}>
                                Select Shift
                              </label>
                              <div className="openDropMain" style={{textAlign:'left',marginBottom: 15}}>
                                <div className="openDropInr columnView">
                                <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="select1"
                                name="radio-buttons-group"
                              >
                                {shiftTimes.map((shiftTime, index) => (<>
                                <FormControlLabel value={shiftTime.unique_shift_time}
                                checked={selectedLocationShiftTime === shiftTime.unique_shift_time}
                                control={<Radio />} label={shiftTime.unique_shift_time} 
                                onChange={(e)=>handleLocationRadioButtonChange(e,locobj.location)}
                                />
                                </>
                                ))}
                              </RadioGroup>
                            </FormControl>
                                </div>
                              </div>

                              <div className="storOpeningMain">


                                {availablecnt > 0 ? (
                                  <div class="repViw">
                                    {avaialableEmployee.map(
                                      (availableobj, index) => (
                                        <p>
                                          {availableobj.completed_tasks == 0 ? (
                                            <em className="repViwColorRed"></em>
                                          ) : (availableobj.completed_tasks /
                                              totalOutOfTask) *
                                              100 <
                                            50 ? (
                                            <em className="repViwColorYellow"></em>
                                          ) : (
                                            <em className="repViwColorGreen"></em>
                                          )}

                                          <span>
                                            {availableobj.full_name
                                              .trim()
                                              .includes(" ")
                                              ? availableobj.full_name.split(
                                                  " "
                                                )[0] +
                                                " " +
                                                availableobj.full_name.split(
                                                  " "
                                                )[1][0]
                                              : availableobj.full_name}{" "}
                                           {employeeTaskList.length > 0 ?   
                                            <b class="repViewCount">
                                              { availableobj.completed_tasks==0? <>{availableobj.completed_tasks} </>:
                                                (percentage = Math.round(
                                                  (parseInt(
                                                    availableobj.completed_tasks
                                                  ) /
                                                    totalOutOfTask) *
                                                    100
                                                ))
                                              }
                                              % (
                                              {parseInt(
                                                availableobj.completed_tasks
                                              )}{" "}
                                              / {totalOutOfTask})
                                            </b>:
                                              <b class="repViewCount">
                                                0%(0{" "}/{" "}0)
                                              </b>}
                                          </span>
                                        </p>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="templateError" style={{marginBottom:10}}>
                                    Employees are not available for{" "}
                                    {locobj.location} location
                                  </div>
                                )}

{completeCount > 0 ? (
                                  <div className="progBarMain">
                                    <div className="progBarInr">
                                      <section>
                                        {completePercentage > 0 ? (
                                          <ol class="progress-bar">
                                            {Array.from(
                                              { length: 10 },
                                              (_, i) =>
                                                completePercentage >=
                                                  10 * (i + 1) &&
                                                completePercentage >=
                                                  10 * (i + 2) ? (
                                                  <li
                                                    key={i}
                                                    class="is-complete"
                                                  ></li>
                                                ) : completePercentage >=
                                                    10 * (i + 1) &&
                                                  completePercentage <=
                                                    10 * (i + 2) ? (
                                                  <li key={i} class="is-active">
                                                    {completePercentage}%
                                                  </li>
                                                ) : (
                                                  <li key={i}></li>
                                                )
                                            )}
                                          </ol>
                                        ) : null}
                                      </section>
                                    </div>
                                  </div>
                                ) : <div className="progBarMain">
                                <div className="progBarInr">
                                  <section>

                                <ol class="progress-bar">
                                      <li class="is-complete is-active">
                                        <span>0%</span>
                                      </li>
                                      <li>
                                        <span>10%</span>
                                      </li>
                                      <li>
                                        <span>20%</span>
                                      </li>
                                      <li >
                                        <span>30%</span>
                                      </li>
                                      <li>
                                        <span>40%</span>
                                      </li>
                                      <li>
                                        <span>50%</span>
                                      </li>
                                      <li >
                                        <span>60%</span>
                                      </li>
                                      <li >
                                        <span>70%</span>
                                      </li>
                                      <li>
                                        <span>80%</span>
                                      </li>
                                      <li>
                                        <span>90%</span>
                                      </li>
                                      <li>
                                        <span>100%</span>
                                      </li>
                                    </ol>

                                  </section>
                                </div>
                              </div>}


                            <div className="listingSearch" style={{marginTop:10}}>
                            <input
                              type="text"
                              placeholder="Search"
                              onChange={(e) =>
                                getSerachList(e.target.value)
                              }
                            />
                            <div className="sortingIcoOut">
                              <ToggleButton
                                value="check"
                                selected={selected}
                                onChange={() => {
                                  handleChangeSortBy();
                                }}
                              >
                                <ImportExportIcon
                                  className="visitorFilterIco"
                                  aria-haspopup="true"
                                />
                              </ToggleButton>
                            </div>
                            </div>

                                

                                {employeeTaskList.length > 0 ? (
                                  <div
                                    className="employyeListingMain"
                                    style={{ marginTop: 10 }}
                                  >
                                    {employeeTaskList.map((takObj, index) => (
                                      <div className={`swipe-container`}>
                                        <div style={{paddingRight: 33}}
                                          className="emploTaskListInr"
                                          id="listID"
                                          onClick={forSelectList}
                                        >

{takObj.is_multiple_person_task?
                                                <GroupIcon className="forTaskIndicatUser"/>:
                                                <PersonIcon className="forTaskIndicatUser"/>}

                                          <div className="withCheck">
                                            <div
                                              className={
                                                takObj.assigntask != null
                                                  ? takObj.assigntask[0]
                                                      .taskstatus == "Assigned"
                                                    ? "class2"
                                                    : "class1"
                                                  : ""
                                              }
                                            >
                                              <img
                                                src={unlock}
                                                className="forClass1"
                                              />
                                              <VerifiedIcon className="forClass2" />
                                              <LockPersonIcon className="forClass3" />
                                            </div>
                                            <div style={{ textAlign: "left" }}>
                                              <h1>{takObj.task_name}</h1>
                                              <p className="locTime">
                                                {takObj.assigntask != null ? (
                                                  <>
                                                    {
                                                      takObj.assigntask[0]
                                                        .shifttime
                                                    }
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </p>
                                              <span className="inrTileTaskName" style={{marginBottom:5}}>
                                                {categoryname}
                                              </span>
                                              <div className="forMultiDayAndTim">
                                              {takObj.assigntask != null ? (
                                                takObj.assigntask.map((multitaskperson, i) => (
                                                  <em className="byWicTx">
                                                    { multitaskperson.full_name},{" "}
                                                    {moment(
                                                      multitaskperson.created_at
                                                    ).format("LTS")}
                                                  </em>
                                                 ))
                                              ) : (
                                                <></>
                                              )}
                                              </div>
                                            </div>
                                          </div>
                                          {takObj.is_beforeafter ? (
                                            <div className="taskImUpload taskImUploadNewSt">
                                              <FilterIcon
                                                onClick={() =>
                                                  handleClickOpen(takObj)
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <>
                                    <div className="dataNoF">
                                      <img src={noPic} />
                                      <p>Data Not Found</p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                          
                      </Slider>
                    </div>

                    {/* <div className="storOpeningMain">
                      <div className="listingSearch">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => getSerachList(e.target.value)}
                        />
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortBy();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>

                      {availablecnt > 0 ? (
                        <div class="repViw">
                          {avaialableEmployee.map((availableobj, index) => (
                            <p>
                              {availableobj.completed_tasks == 0 ? (
                                <em className="repViwColorRed"></em>
                              ) : (availableobj.completed_tasks /
                                  totalOutOfTask) *
                                  100 <
                                50 ? (
                                <em className="repViwColorYellow"></em>
                              ) : (
                                <em className="repViwColorGreen"></em>
                              )}

                              <span>
                                {availableobj.full_name.trim().includes(" ")
                                  ? availableobj.full_name.split(" ")[0] +
                                    " " +
                                    availableobj.full_name.split(" ")[1][0]
                                  : availableobj.full_name}{" "}
                                <b class="repViewCount">
                                  {
                                    (percentage = Math.round(
                                      (parseInt(availableobj.completed_tasks) /
                                        totalOutOfTask) *
                                        100
                                    ))
                                  }
                                  % ({parseInt(availableobj.completed_tasks)} /{" "}
                                  {totalOutOfTask})
                                </b>
                    
                              </span>
                            </p>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}

                      {completeCount > 0 ? (
                        <div className="progBarMain">
                          <div className="progBarInr">
                            <section>
                              {completePercentage > 0 ? (
                                <ol class="progress-bar">
                                  {Array.from({ length: 10 }, (_, i) =>
                                    completePercentage >= 10 * (i + 1) &&
                                    completePercentage >= 10 * (i + 2) ? (
                                      <li key={i} class="is-complete"></li>
                                    ) : completePercentage >= 10 * (i + 1) &&
                                      completePercentage <= 10 * (i + 2) ? (
                                      <li key={i} class="is-active">
                                        {completePercentage}%
                                      </li>
                                    ) : (
                                      <li key={i}></li>
                                    )
                                  )}
                                </ol>
                              ) : null}
                            </section>
                          </div>
                        </div>
                      ) : null}

                      {employeeTaskList.length > 0 ? (
                        <div
                          className="employyeListingMain"
                          style={{ marginTop: 10 }}
                        >
                          {employeeTaskList.map((takObj, index) => (
                            <div className={`swipe-container`}>
                              <div
                                className="emploTaskListInr"
                                id="listID"
                                onClick={forSelectList}
                              >
                                <div className="withCheck">
                                  <div
                                    className={
                                      takObj.assigntask != null
                                        ? takObj.assigntask[0].taskstatus ==
                                          "Assigned"
                                          ? "class2"
                                          : "class1"
                                        : ""
                                    }
                                  >
                                    <img src={unlock} className="forClass1" />
                                    <VerifiedIcon className="forClass2" />
                                    <LockPersonIcon className="forClass3" />
                                  </div>
                                  <div style={{ textAlign: "left" }}>
                                    <h1>{takObj.task_name}</h1>
                                    <span className="inrTileTaskName">
                                      {categoryname}
                                    </span>
                                    {takObj.assigntask != null ? (
                                      <em className="byWicTx">
                                        {takObj.assigntask != null
                                          ? takObj.assigntask[0].full_name
                                          : null}
                                        ,{" "}
                                        {moment(
                                          takObj.assigntask[0].created_at
                                        ).format("LTS")}
                                      </em>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                {takObj.is_beforeafter ? (
                                  <div className="taskImUpload">
                                    <FilterIcon
                                      onClick={() => handleClickOpen(takObj)}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <div className="dataNoF">
                            <img src={noPic} />
                            <p>Data Not Found</p>
                          </div>
                        </>
                      )}
                    </div> */}
                  </div>
                </CustomTabPanel>
              ))}
            </Box>
          </div>
        </div>

        {/* <div className="profileSwitchOuter"> */}
        <AdminBottomNav />
        {/* <div className="profileSwitchMain">
            <div className="profileSwitchInr">
              <Button
                onClick={() => navigate("/ReportAdmin")}
                className="activeProSwich"
              >
                <ApartmentIcon /> Business
              </Button>
              <Button onClick={() => navigate("/PersonalTaskNew")}>
                <PermIdentityIcon /> Personal
              </Button>
            </div>
          </div> */}
        {/*  </div> */}

        {/* <FloatButton route1={"EmployeeTaskList"} /> */}
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore ? (
                        <>
                          {ImageUploadBefore.map((img) => (
                            <div className="befAftImgDiv">
                              {img.match(
                                /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i
                              ) ? (
                                <Zoom>
                                  <img src={img} />
                                </Zoom>
                              ) : img.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i) ? (
                                <div className="videoPlaMain">
                                  <video width="100%" height="85" controls>
                                    <source src={img} type="video/mp4" />
                                  </video>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter ? (
                        <>
                          {ImageUploadAfter.map((img) => (
                            <div className="befAftImgDiv">
                              {img.match(
                                /.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i
                              ) ? (
                                <Zoom>
                                  <img src={img} />
                                </Zoom>
                              ) : img.match(/.(mp4|MP4|3gp|3GP|flv|FLV)$/i) ? (
                                <div className="videoPlaMain">
                                  <video width="100%" height="85" controls>
                                    <source src={img} type="video/mp4" />
                                  </video>
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  // function NextArrow(props) {
  //   const datesArrowArrayVal = getArrowDatesArray(startDate, endDate);
  //   const selectedDate = datesArrowArrayVal[props.currentSlide];
  //   console.log("Selected date:", selectedDate);
  //   if (startADate != selectedDate) {
  //     setStartADate(selectedDate);
  //     const date = moment(selectedDate);
  //     const dayName = date.format("dddd");
  //     setWeekDay(dayName);
  //     ChangeDateFun(categoryid, selectedDate, dayName);
  //   }
  //   return (
  //     <button class="slick-arrow slick-next" onClick={props.onClick}>
  //       {" "}
  //       Next
  //     </button>
  //   );

  //   return false;
  // }
}

export default ReportAdmin;

import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function CheckboxGroup(props) {
  const { label, name, options, labelClass,isRequired, ...rest } = props;
  return (
    <div className="form-control">
      <label className={labelClass}>{label}</label>
      {isRequired == "true" ? <span className="requiredStar">*</span> : <></>}
      <div className="valRadioOut">
        <Field name={name}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <div className="valRadioInr">
                    <input
                      type="checkbox"
                      id={option.key}
                      {...field}
                      //{...rest}
                      value={option.key}
                      //checked={field.value.includes(option.value)}
                    />{"   "}
                    <label htmlFor={option.key}>{option.value}</label>
                  </div>
                </React.Fragment>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default CheckboxGroup;

import React, { useContext, useEffect } from "react";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { NotificationContext } from "./NotificationContext";
import { useNavigate } from "react-router-dom";
import dashLogo from "../../src/assets/images/dashLogo.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";

function NotificationIcon() {
  const navigate = useNavigate();

  const {
    addNotification,
    notifications,
    notificationCount,
    removeNotification,
  } = useContext(NotificationContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate("/NotificationPage")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (index) => {
    removeNotification(index);
  };

  return (
    <>
      <div className="notiFixedPatch"></div>
      <div className="notificationOuter">
        {/* <div className="dashLogo">
          <div className="dashLogoOut">
            {logo != "" ? <img src={logo} /> : null}
          </div>
          <span>{company_name}</span>
        </div> */}

        <div className="notificationInner">
          <IconButton className="TouchableIconBtn">
            {" "}
            <NotificationsOutlinedIcon
              className="notiIco"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          </IconButton>

          <p className="notiCounts" onClick={() => navigate("/NotificationPage")}>{notificationCount}</p>

          <div className="">
            {/* <Menu
              className="mobNotiFicMain"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            > */}
              {/* <MenuItem>
                Notification text here..! <em></em>
              </MenuItem> */}
              {/* {notifications.length > 0 &&
                notifications.map((item, index) => (
                  <MenuItem onClick={() => handleNotificationClick(index)}>
                    {item}
                    <em></em>
                  </MenuItem>
                ))}
            </Menu> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationIcon;

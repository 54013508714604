import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import avtarPic from "../../../assets/images/avtarPic.png";
import noLogo from "../../../assets/images/noLogo.jpg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import swal from "sweetalert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import editIco from "../../../assets/images/edit-icon.svg";
import {
  makeGetRequest,
  makeNewGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import { styled } from "@mui/material/styles";
import SuperAdminBottomTab from "./SuperAdminBottomTab";
import imageCompression from "browser-image-compression";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddCompanyAdmin() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [TempUser, setTempUser] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const [fullname, setFullname] = React.useState("");
  const [employeeCode, setEmployeeCode] = React.useState(0);

  const [password, setPassword] = React.useState("");
  const [emailid, setEmailid] = React.useState("");
  const [isCompany, setisCompany] = React.useState(0);
  const [contactno, setContactno] = React.useState("");
  const [companyDropDown, setcompanyDropDown] = React.useState([]);
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [companySchemaName, setCompanySchemaName] = React.useState("");
  const [profilePic, setProfilePic] = React.useState("");
  const [duplicateEmailErrMsg, setDuplicateEmailErrMsg] = React.useState("");
  const [duplicateContactErrMsg, setDuplicateContactErrMsg] =
    React.useState("");
  const [employeeCodeErrMsg, setEmployeeCodeErrMsg] = React.useState("");
  const [companyErrMsg, setCompanyErrMsg] = React.useState("");
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [deviceUserType, setDeviceUserType] = useState("activeUser");

  const initialValues = {
    employee_code: "",
    full_name: "",
    email_id: "",
    contact_no: "",
    password: "",
    device_user_type: deviceUserType,
  };

  const validationSchema = Yup.object({
    employee_code: Yup.string()
      .max(8, "8 Digit employee code")
      .trim("White spaces not allow")
      .strict(true).required("Required"),
    full_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    email_id: Yup.string().matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      "Invalid Email Id"
    ),
    contact_no: Yup.string()
      .length(10, "Phone no should be 10 digits long")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Required"),
    password: Yup.string()
      /* .length(4, "Password should be 4 digits long")
      .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{1,3}\\)[ \\-]*)|([0-9]{1,4})[ \\-]*)*?[0-9]{1,4}?[ \\-]*[0-9]{1,4}?$/,
      "Password is not valid"
    ) */
      .required("Required"),
    device_user_type: Yup.string().required("Required"),
  });

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;

    await uploadFileRequest("superadmin/upload", formData)
      .then((response) => {
        fileUrl = response.data.imagepath;
      })
      .catch((err) => {
        setShowLoader(false);
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function compressLogoImage(file) {
    var uploadDone;
    const options = {
      maxSizeMB: 1, // Set the max size to 1MB
      maxWidthOrHeight: 1024, // Set the maximum width or height
      useWebWorker: true, // Use web worker for better performance
    };
    try {
      const imageCompress = await imageCompression(file, options);
      const uniqueFileName = `${Date.now()}_${imageCompress.name}`;
      const renamedFile = new File([imageCompress], uniqueFileName, {
        type: imageCompress.type,
      });
      uploadDone = await uploadImageOrFile(renamedFile);
      setProfilePic(uploadDone);
      setShowLoader(false);
      return uploadDone;
    } catch (error) {
      console.log(error);
    }
  }

  async function uploadProfilePic(e) {
    setShowLoader(true);
    const file = e.target.files[0];
    if (await validateImage(file)) {
      if (await compressLogoImage(file)) {
        console.log("In compress", file);
        setShowLoader(false);
      } else {
        console.log("Image Compress issue");
        setShowLoader(false);
      }
      setShowLoader(false);
    } else {
      setProfileErrMsg("Invalid file format");
      setShowLoader(false);
    }
  }

  const [passwordLength, setPasswordLength] = useState(8);
  const [useSymbols, setUseSymbols] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useLowerCase, setUseLowerCase] = useState(true);
  const [useUpperCase, setUseUpperCase] = useState(true);
  const [upload, setUpload] = React.useState(false);

  const generatePassword = () => {
    let charset = "";
    let newPassword = "";

    if (useSymbols) charset += "!@#$%^&*()";
    if (useNumbers) charset += "0123456789";
    if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return newPassword;
  };

  const getAllCompanyList = () => {
    //if (!searchString) searchString = null;
    setShowLoader(true);
    setcompanyDropDown([]);

    companyDropDown.length = 0;
    makeGetRequest("superadmin/getcompanylistasc")
      .then((response) => {
        setcompanyDropDown([]);
        let companyassign = [];
        console.log("My editsd===>" + JSON.stringify(response.data.data));

        if (response.data.data) {
          let companyObj;

          if (response.data.data.length > 0) {
            companyassign.push({ key: "Select Company", value: "" });
            for (let i = 0; i < response.data.data.length; i++) {
              companyObj = { key: "", value: "" };
              companyObj.key = response.data.data[i].company_name;
              companyObj.value = response.data.data[i].company_id;
              console.log("company school===>" + JSON.stringify(companyObj));
              companyassign.push(companyObj);
            }
            console.log("Edited compnay===>" + JSON.stringify(companyassign));
            setcompanyDropDown(companyassign);
          } else {
            setcompanyDropDown(companyassign);
          }
          setUpload(!upload);
        }

        // if (companyDropDown.length == 0) {
        //   swal(
        //     "Warning",
        //     "Company list is empty. Please add company first",
        //     "warning",
        //     { buttons: false, timer: 3000 }
        //   );
        //   navigate("/SuperAdminDashboard");
        // }
        //
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const statusChangeFun = async (status) => {
    setDropDownSelect(status.target.value);
    console.log("Selected Value", status.target.value);
    let idx = status.target.selectedIndex;
    let dataset = status.target.options[idx].dataset;
    setCompanySchemaName(dataset.isd.replaceAll(" ", "").toLowerCase());
    console.log(
      "dataset.isd==>",
      dataset.isd.replaceAll(" ", "").toLowerCase()
    );
    const schemaName = dataset.isd.replaceAll(" ", "").toLowerCase();
    await makeNewGetRequest(
      "employee/getlastemployee/" + status.target.value + "/" + schemaName
    )
      .then((response) => {
        if (response.data.data) {
          let newEmployeeCode;
          if (response.data.data.rows.length > 0) {
            console.log(response.data.data.rows[0].employee_id);
            let newUserCode =
              parseInt(response.data.data.rows[0].employee_id) + 1;
            newEmployeeCode = `${String(newUserCode).padStart(5, "0")}`;
            //console.log(newEmployeeCode);
          } else {
            let newUserCode = 1;
            newEmployeeCode = `${String(newUserCode).padStart(5, "0")}`;
          }
          setEmployeeCode(newEmployeeCode);
          const savedValues = {
            company_id: parseInt(dropDownSelect),
            user_type: "Admin",
            full_name: fullname,
            employee_code: newEmployeeCode,
            password: password,
            email_id: emailid,
            contact_no: contactno,
            is_admin: isAdmin,
            status: isActive,
            profile_pic: profilePic,
            schemaname: companySchemaName,
            device_user_type: deviceUserType,
          };
          setFormValues(savedValues);
        } else {
          alert("else");
        }
      })
      .catch((err) => {});
  };

  const addOrUpdateAdmin = (values) => {
    setShowLoader(true);
    setDuplicateEmailErrMsg("");
    setDuplicateContactErrMsg("");
    setCompanyErrMsg("");
    if (dropDownSelect) {
      values.company_id = parseInt(dropDownSelect);
      if (isAdmin) values.user_type = "Admin";
      else values.user_type = "User";
      values.status = isActive;
      values.is_admin = isAdmin;
      values.profile_pic = profilePic;
      values.schemaname = companySchemaName;
      values.password = password;
      //console.log(values)
      makePostRequest("employee/insertemployee", values, values.schemaname)
        //makePostRequest("employee/insertemployee", values)
        .then((response) => {
          setShowLoader(false);
          if (response.data) {
            navigate("/AdminList");
          }
        })
        .catch((err) => {
          swal("Warning", err.response.data.message, "warning", {
            timer: 3000,
            buttons: false,
          });
          setShowLoader(false);
        });
      setDuplicateEmailErrMsg("");
      setDuplicateContactErrMsg("");
    } else {
      setCompanyErrMsg("Please Select Company");
      setShowLoader(false);
    }
  };

  const checkAdminStatus = (status) => {
    console.log("StatusAdmin", status);
    setIsAdmin(status);
  };
  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };

  const handleKeyPress = (e) => {
    if (e.target.value != "") {
      setContactno(e.target.value);
      let lastFourDigits = e.target.value.substr(-4);
      setPassword(lastFourDigits);
      const savedValues = {
        company_id: parseInt(dropDownSelect),
        user_type: "Admin",
        full_name: fullname,
        employee_code: employeeCode,
        password: lastFourDigits,
        email_id: emailid,
        contact_no: e.target.value,
        is_admin: isAdmin,
        status: isActive,
        profile_pic: profilePic,
        schemaname: companySchemaName,
        device_user_type: deviceUserType,
      };
      setFormValues(savedValues);
    }
  };

  const handleEmail = (e) => {
    if (e.target.value != "") {
      setEmailid(e.target.value);
    }
  };

  const handleFname = (e) => {
    if (e.target.value != "") {
      setFullname(e.target.value);
    }
  };

  const handleEmployeeCode = (e) => {
    if (e.target.value != "") {
      setEmployeeCode(e.target.value);
    }
  };
  const handleDeviceUserType = (e) => {
    if (e != "") {
      setDeviceUserType(e);
    }
  };

  React.useEffect(() => {
    getAllCompanyList(null);
  }, []);

  return (
    <div>
      <div className="wrapper innerPagesMain">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>

        {/* TopBarEnd */}
        <Grid item xs={12} md={12}>
          <div className="innerPageTopSec">
            <div className="forBackArrow">
              <NavigateBeforeIcon onClick={() => navigate(-1)} />
            </div>
            <h1> {isEditAdmin ? <>Edit</> : <>Add</>} Company Admin</h1>
            <div className="forBackArrow"></div>
          </div>
        </Grid>
        <div className="addEdiProfDivOut">
          <div className="addEdiProfDivInnrIconAtt">
            <div className="addEdiProfDivInnr">
              {profilePic ? <img src={profilePic} /> : <img src={avtarPic} />}
            </div>
            <Button
              className="profEdiICoDiv"
              component="label"
              role={undefined}
              tabIndex={-1}
            >
              <img src={editIco} />
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => uploadProfilePic(e)}
              />
            </Button>
          </div>
          <span className="errorSt">{profileErrMsg}</span>
        </div>
        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={addOrUpdateAdmin}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <label className="myLable">Select Company</label>
                    <select
                      value={dropDownSelect}
                      onChange={statusChangeFun}
                      className="myInput"
                    >
                      {companyDropDown.map((catObj) => (
                        <option
                          className="myLable"
                          value={catObj.value}
                          data-isd={catObj.key}
                        >
                          {catObj.key}
                        </option>
                      ))}
                    </select>
                    <span className="errorSt">{companyErrMsg}</span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Code"
                      placeholder="Employee Code"
                      labelClass="myLable"
                      value={employeeCode}
                      onChange={(e) => handleEmployeeCode(e)}
                      name="employee_code"
                      className="myInput"
                      onBlur={(e) => handleEmployeeCode(e)}
                      isRequired="true"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Full Name"
                      placeholder="Full Name"
                      labelClass="myLable"
                      name="full_name"
                      className="myInput"
                      onBlur={(e) => handleFname(e)}
                      isRequired="true"
                    />
                  </Grid>
                  {isEditAdmin ? (
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Enter Mobile Number"
                        placeholder="Mobile Number"
                        labelClass="myLable"
                        name="contact_no"
                        className="myInput"
                        isRequired="true"
                      />
                      <span className="errorSt">{duplicateContactErrMsg}</span>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12}>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Enter Mobile Number"
                        placeholder="Mobile Number"
                        labelClass="myLable"
                        name="contact_no"
                        className="myInput"
                        onBlur={(e) => handleKeyPress(e)}
                        isRequired="true"
                      />
                      <span className="errorSt">{duplicateContactErrMsg}</span>
                    </Grid>
                  )}
                  {/*  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Last Name"
                      placeholder="Employee Name"
                      labelClass="myLable"
                      name="last_name"
                      className="myInput"
                    />
                  </Grid> */}
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type={isEditAdmin ? "password" : "text"}
                      label="Enter Employee Pin"
                      placeholder="Pin"
                      labelClass="myLable"
                      name="password"
                      className="myInput"
                      onBlur={(e) => setPassword(e.target.value)}
                      isRequired="true"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Email"
                      placeholder="Email"
                      labelClass="myLable"
                      name="email_id"
                      className="myInput"
                      onBlur={(e) => handleEmail(e)}
                    />
                    <span className="errorSt">{duplicateEmailErrMsg}</span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="select"
                      label="Select Device User Type"
                      name="device_user_type" // Formik field name
                      options={[
                        { key: "Select an option", value: "" },
                        { key: "Active User", value: "activeUser" },
                        { key: "Test User", value: "testUser" },
                      ]} // Array of options
                      labelClass="myLable"
                      className="myInput"
                      onBlur={(e) => handleDeviceUserType(e)}
                      isRequired="true"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isActive}
                            onChange={(e) =>
                              checkActiveStatus(e.target.checked)
                            }
                          />
                        }
                        label="Active"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isAdmin}
                            onChange={(e) => checkAdminStatus(e.target.checked)}
                          />
                        }
                        label="Is this Admin User?"
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={12}>
                    <div className="dtOuter">
                      <FormikControl
                        control="date"
                        type="date"
                        label="Date of joining"
                        placeholder="Date of Joining"
                        labelClass="myLable"
                        name="date_of_joining"
                        className="myInput"
                        />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Code"
                      placeholder="Employee Code"
                      labelClass="myLable"
                      name="employee_code"
                      className="myInput"
                    />
                    <span className="errorSt">{employeeCodeErrMsg}</span>
                  </Grid> */}

                  <Grid item xs={12} md={12}>
                    <Button type="submit" className="logginBt">
                      {isEditAdmin ? <>Edit</> : <>Add</>} Company Admin
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <SuperAdminBottomTab />
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AddCompanyAdmin;

import React, { useContext } from "react";
import AppDrawer from "../AppDrawer";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../components/NotificationContext";
import NotificationIcon from "../../components/NotificationIcon";
function TopBar() {
  const navigate = useNavigate();

  const { addNotification } = useContext(NotificationContext);

  return (
    <>
      {/* <div className="topBarPatch"></div> */}
      <div className="topBarMain">
        <AppDrawer />
        {/* <Badge
          badgeContent={4}
          className="notiSt"
          onClick={() => navigate("/NotificationPage")}
        >
          <NotificationsIcon color="action" />
        </Badge> */}
        <NotificationIcon/>
      </div>
    </>
  );
}

export default TopBar;

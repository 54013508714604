import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Box, Button } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "react-medium-image-zoom/dist/styles.css";
import EastIcon from "@mui/icons-material/East";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import Slider from "react-slick";
import IconButton from "@mui/material/IconButton";
import { TimeRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import edit from "../../../assets/images/edit2.svg";
import deleteIm from "../../../assets/images/Delete.svg";
import users from "../../../assets/images/Users.svg";
import { weekdays } from "moment/moment";
import { toast } from "react-toastify";
import { parse } from "date-fns";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import acordinDeleteIm from "../../../assets/images/Delete.svg";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { setIn } from "formik";
import { DateRange } from "@mui/icons-material";

function CreateNewSchedule() {
  const navigate = useNavigate();
  const location = useLocation();

  const [empCount, setEmpCount] = React.useState(1); // Initialize employee count
  const [freqList, setFreqList] = React.useState([]);

  async function getAllFrequancyList(locname, frequancyid, day) {
    if (locname == "") {
      await makeGetRequest("frequancy/getfrequancylist/frequancy_name/asc/null")
        .then((response) => {
          if (response.data.data.res) {
            console.log("freq>> " + response.data.data.res);
            if (response.data.data.res.length > 0) {
              setFreqList(response.data.data.res);
            } else {
              setFreqList([]);
            }
          }
        })
        .catch((err) => {
          //setShowLoader(false);
        });
    } else {
      let bodyFormData = {
        location: locname,
        templateName: templatename,
        frequencyId: frequancyid,
        weekday: day,
      };

      console.log(bodyFormData);

      // return false;

      await makePostRequest(
        "schedular/getUnusedFrequenciesByLocation",
        bodyFormData
      )
        .then((response) => {
          if (response.data) {
            setFreqList(response.data.data);
          } else {
            setFreqList([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const validateInput = (input) => {
    // Remove leading/trailing whitespaces and check if the result is empty
    if (input.trim() === "") {
      return false; // Invalid input
    }
    return true; // Valid input
  };

  const handleTimeAdd = (time, locobj, index) => {
    {
      const newShifts = [...(shiftState[locobj.location] || [])];
      newShifts[index].shiftTime = time;
      setShiftState({
        ...shiftState,
        [locobj.location]: newShifts,
      });
    }
  };

  // Function to handle changes in the TimeRangePicker
  const handleTimeChange = (newTimeRange) => {
    // Handle the case where `timeRange` is null or invalid
    if (!newTimeRange || !Array.isArray(newTimeRange)) {
      setStartTime(null); // Reset to default or null
      setEndTime(null);
      return;
    }

    const [newStartTime, newEndTime] = newTimeRange;
    setStartTime(newStartTime);
    setEndTime(newEndTime);

    // Update the shift timing in editshift
    SetEditShift((prevShift) => ({
      ...prevShift,
      shift_timing: `${formatTime(newStartTime)}-${formatTime(newEndTime)}`,
    }));
  };

  // Function to increment employee count
  const incrementCount2 = () => {
    SetEditShift((prevShift) => ({
      ...prevShift,
      emp_count: prevShift.emp_count + 1, // Increment emp_count
    }));
  };

  // Function to decrement employee count
  const decrementCount2 = () => {
    SetEditShift((prevShift) => ({
      ...prevShift,
      emp_count: prevShift.emp_count > 1 ? prevShift.emp_count - 1 : 1, // Decrement emp_count but ensure it stays above 1
    }));
  };

  const updateShift = async (editshift) => {
    const formattedShiftTime =
      editshift.shift_timing || `${startTimeedit}-${endTimeedit}`;

    // Make sure emp_count is updated and passed
    const empCount = editshift.emp_count;

    let bodyFormData = {
      sch_id: editschid,
      Key: editshift.Key,
      Shifttime: formattedShiftTime,
      EmpCount: empCount,
      Frequencyid: editFreqid,
    };

    // Make API request to update the shift
    await makePostRequest(
      "schedular/UpdateAdminSchedularByIdandKey",
      bodyFormData
    )
      .then((response) => {
        console.log("Shift updated successfully:", response.data);
        // toast.success("Update Done Successfully!", {
        //   position: "top-center", // Set the position here
        //   autoClose: 1500,
        // });
        swal(
          "Success",
          "Update complete! Your changes will take effect next week",
          "success",
          {
            buttons: true,
          }
        );
        handleClose();
        locationList(selectedDay); // Refresh the list after update
      })
      .catch((error) => {
        console.error("Error updating shift:", error);
      });
  };

  // Define a function to parse time string
  function parseTimeString(timeStr) {
    return parse(timeStr, "hh:mm aa", new Date());
  }

  // For Modal
  const [open, setOpen] = React.useState(false);
  const [editshift, SetEditShift] = React.useState({});
  const [editschid, SetEditSchid] = React.useState({});
  const [editloc, SetEditLoc] = React.useState("");
  const [startTimeedit, setStartTime] = React.useState();
  const [endTimeedit, setEndTime] = React.useState();
  const [editFreqid, setFreqid] = React.useState();

  const handleClickOpen = (shiftsobj, locobj) => {
    SetEditShift(shiftsobj);
    SetEditSchid(locobj.sch_id);
    SetEditLoc(locobj.location);
    setFreqid(shiftsobj.frequancyid);
    console.log(shiftsobj);
    console.log(locobj);

    getAllFrequancyList(locobj.location, shiftsobj.frequancyid, selectedDay);

    const shiftTiming = shiftsobj.shift_timing; // e.g., "6:00 PM-8:00 PM"
    const [startTimeStr, endTimeStr] = shiftTiming
      ? shiftTiming.split("-")
      : [null, null];

    // Parse the start and end times
    const startTime = startTimeStr
      ? parseTimeString(startTimeStr.trim())
      : null;
    const endTime = endTimeStr ? parseTimeString(endTimeStr.trim()) : null;

    setStartTime(startTime);
    setEndTime(endTime);

    setOpen(true);
  };

  // const handleClickOpen = (shiftsobj,locobj) => {
  //   SetEditShift(shiftsobj);
  //   SetEditSchid(locobj.sch_id);
  //   console.log("edit>>",shiftsobj);
  //   console.log("edit>>",locobj);
  //   const shiftTiming = shiftsobj.shift_timing; // e.g., "6:00 PM-8:00 PM"
  //   console.log(shiftTiming);
  //   const [startTimeStr, endTimeStr] = shiftTiming ? shiftTiming.split('-') : [null, null];

  //   // Parse the start and end times
  //    const startTime = startTimeStr ? parseTimeString(startTimeStr.trim()) : null;
  //    const endTime = endTimeStr ? parseTimeString(endTimeStr.trim()) : null;

  //    setStartTime(startTime);
  //    setEndTime(endTime);

  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  const [shiftLabels, setShiftLabels] = useState({}); // State to track shift labels by location and shift

  const handleChangeShiftLabelEdit = (e) => {
    // alert("in"+e.target.value);
    setFreqid(e.target.value);
  };

  const handleChangeShiftLabel = (e, locId, index) => {
    const newShifts = [...(shiftState[locId] || [])];
    newShifts[index].Frequencyid = e.target.value;
    // setShiftLabels({
    //   ...shiftState,
    //   [locId]: newShifts,
    // });
    setShiftState({
      ...shiftState,
      [locId]: newShifts,
    });
    setShiftNameError("");
  };

  const [locname, setLocationName] = useState("");
  const [templatename, setTemplateName] = useState(
    localStorage.getItem("templatename") || ""
  );

  const handleEditTemplate = async (e) => {
    const userInput = e.target.value;
    if (!validateInput(userInput)) {
      swal(
        "Warning",
        "Input is invalid: Only whitespaces are not allowed.",
        "warning"
      );
      return;
    } else {
      console.log("Input is valid.");
    }

    if (e.target.value != "") {
      const template = e.target.value;
      localStorage.setItem("templatename", template.trim());

      let obj = {
        old_template_name: old_template_name,
        new_template_name: template.trim(),
      };

      await makePostRequest("schedular/updateTemplateName", obj)
        .then((response) => {
          if (response.data) {
            // navigate("/AdminTasksList");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      swal("Shift Schedule Name is Mandatory");
      return;
    }
  };

  const [loc, setLoc] = useState([]);
  // const [expanded, setExpanded] = useState(false);
  const [selectedDay, setSelectedDay] = useState("Monday");
  const [shiftState, setShiftState] = useState({});
  const [hoursOfOperation, setHoursOfOperation] = useState({
    start: "09:00AM",
    end: "10:00PM",
  });
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(false); // Default checked

  const dayMapping = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
    All: "All", // Assuming 'All' selects all days
  };

  const [selectedDays, setSelectedDays] = useState({
    All: false,
    M: true,
    T: false,
    W: false,
    Th: false,
    F: false,
    S: false,
    Su: false,
  });

  const toggleDaySelection = (day) => {
    setSelectedDays((prev) => {
      // If "All" is clicked and it's currently active, reset all days to false
      if (day === "All" && prev.All) {
        return {
          All: false,
          M: false,
          T: false,
          W: false,
          Th: false,
          F: false,
          S: false,
          Su: false,
        };
      }

      // If "All" is clicked and it's not currently active, activate all days
      if (day === "All") {
        return {
          All: true,
          M: true,
          T: true,
          W: true,
          Th: true,
          F: true,
          S: true,
          Su: true,
        };
      }

      // For other days, toggle the selected day and deselect "All" if any individual day is selected/deselected
      return {
        ...prev,
        All: false,
        [day]: !prev[day],
      };
    });
  };

  const handleSwitchChange = async (event) => {
    setIsDefaultTemplate(event.target.checked); // Update state based on switch value
    var bodyFormData = {
      template_name: templatename,
      is_active: event.target.checked,
    };

    await makePostRequest(
      "schedular/ChangeAdminScheduleTemplate",
      bodyFormData
    ).then((response) => {
      if (response.data) {
        locationList(selectedDay);
      }
    });
  };

  async function addLocation() {
    let companyid = localStorage.getItem("companyId");

    if (!validateInput(locname)) {
      swal(
        "Warning",
        "Input is invalid: Only whitespaces are not allowed.",
        "warning"
      );
      return;
    } else {
      console.log("Input is valid.");
    }

    let loc_name = locname;
    var bodyFormData = {
      company_id: parseInt(companyid),
      loc_name: loc_name.trim(),
    };

    await makePostRequest("location/insertLocation", bodyFormData)
      .then((response) => {
        if (response.data) {
          locationList(selectedDay);
          setLocationName("");
        }
      })
      .catch((err) => {
        const errorMessage =
          err?.response?.data?.message || "An unexpected error occurred.";
        swal("Warning", errorMessage, "warning");
      });
  }
  // Format time functions
  const formatTime = (date) => {
    if (!date) return "";
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const formatShiftTime = (shiftTime) => {
    if (shiftTime && shiftTime.length === 2) {
      return `${formatTime(shiftTime[0])}-${formatTime(shiftTime[1])}`;
    }
    return "";
  };

  const handleDayChange = (day) => {
    setSelectedDay(day);
    reloadLocationListing(day);
  };

  const reloadLocationListing = (day) => {
    locationList(day);
  };

  const [old_template_name, setOldTemplateName] = React.useState("");
  async function locationList(day) {
    setOldTemplateName(localStorage.getItem("templatename"));
    let bodyFormData = {
      weekday: day,
      template_name: localStorage.getItem("templatename"),
    };
    await makePostRequest(
      "schedular/GetAdminInitialLocationShiftsSchedule/",
      bodyFormData
    )
      .then((response) => {
        if (response.data.data) {
          setLoc(response.data.data);

          // Initialize shiftState with one default shift per location
          const initialShiftState = {};
          response.data.data.forEach((locobj) => {
            initialShiftState[locobj.location] = [
              { id: Date.now(), empCount: 1, shiftTime: [null, null] },
            ];
          });
          setShiftState(initialShiftState);
        } else {
          setLoc([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Add a new shift block for a specific location
  const addMoreShift = (locId) => {
    setShiftState({
      ...shiftState,
      [locId]: [
        ...(shiftState[locId] || []),
        { id: Date.now(), empCount: 1, shiftTime: [null, null] },
      ],
    });
  };

  //   // Function to check if all shiftTime arrays contain only [null, null]
  const isShiftTimeNull = () => {
    return Object.values(shiftState).every((shifts) =>
      shifts.every(
        (shift) => shift.shiftTime[0] === null && shift.shiftTime[1] === null
      )
    );
  };

  //  const isDisabled = isShiftTimeNull()

  // Function to check if any Frequencyid is empty or null
  const isFrequencyEmpty = () => {
    return Object.values(shiftState).every((shifts) =>
      shifts.every(
        (shift) =>
          shift.Frequencyid === "" ||
          shift.Frequencyid === null ||
          shift.Frequencyid === undefined
      )
    );
  };

  let isFrequencyDisabled = isFrequencyEmpty();

  let isDisabled = isShiftTimeNull();
  console.log(isDisabled);

  const deleteShift = async (sch_id, Key) => {
    setShowLoader(true);
    console.log(sch_id, Key);
    swal({
      title: "Confirm Delete?",
      text: "This will permanently delete the Employee Shift.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            sch_id: sch_id,
            key: Key,
          };
          const response = await makePostRequest(
            "schedular/DeleteAdminSchedularByIdandKey",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            locationList(selectedDay);
          }
        } catch (error) {
          console.error(`Error deleting shifts for ${selectedDay}:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  const deleteTemplateLocation = async (loc_name) => {
    setShowLoader(true);
    console.log(loc_name);
    swal({
      title: "Are you sure?",
      text: "You want to delete this location for the template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name: templatename,
            new_location: loc_name,
          };
          const response = await makePostRequest(
            "schedular/deleteTemplateLocation",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            locationList(selectedDay);
          } else {
            setShowLoader(false);
            locationList(selectedDay);
          }
        } catch (error) {
          console.error(`Error deleting location for template`, error);
        }
      } else {
        setShowLoader(false);
        //swal("Your file is safe!");
      }
    });
  };

  const [shiftnameerror, setShiftNameError] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const saveShift = async (locId, loc_name) => {
    // setShiftNameError("");
    setShowLoader(true);

    if (!validateInput(templatename)) {
      swal(
        "Warning",
        "Input is invalid: Only whitespaces are not allowed for template name.",
        "warning"
      );
      return;
    } else {
      console.log("Input is valid.");
    }

    // Get all selected days or handle "All" case
    const activeDays = selectedDays.All
      ? ["M", "T", "W", "Th", "F", "S", "Su"] // All days initials
      : Object.keys(selectedDays).filter((day) => selectedDays[day]);

    // Convert initials to full day names using dayMapping
    const fullDayNames = activeDays.map((initial) => dayMapping[initial]);

    console.log("fullDayNames >> ",fullDayNames);

    if(fullDayNames.length==0)
    {
      swal("Warning","Please select day to apply this shift","warning");
      setShowLoader(false);
      return;
    }


    console.log(shiftState[locId]);


    const shiftsarr = shiftState[locId] || [];
    // Iterate over the shifts array
    for (const shift of shiftsarr) {
      if (
        !Array.isArray(shift.shiftTime) ||
        shift.shiftTime.length === 0 ||
        shift.shiftTime.includes(null) ||
        shift.shiftTime.includes("") ||
        shift.shiftTime.includes("-")
      ) {
        console.log("Shift Time is Mandatory");
        setShiftNameError("Shift Time is Mandatory");
        setShowLoader(false); // Assuming setShowLoader stops the loader
        return; // Stops the function execution
      }
    }

    //  return false;

    const hasMissingFrequencyid = shiftState[locId].some(
      (item) => !item.Frequencyid
    );

    if (hasMissingFrequencyid) {
      console.log("Shift Name is Mandatory");
      setShiftNameError("Shift Name is Mandatory");
      setShowLoader(false);
      return;
    } else {
      setShiftNameError("");
    }

    const hasDuplicateFrequencyId = (shifts) => {
      const frequencyIds = shifts.map((shift) => shift.Frequencyid); // Extract all Frequencyid values
      const uniqueFrequencyIds = new Set(frequencyIds); // Create a Set to store unique Frequencyid values
      return uniqueFrequencyIds.size !== frequencyIds.length; // Compare the size of unique values with the original length
    };

    // Example usage:
    if (hasDuplicateFrequencyId(shiftState[locId])) {
      console.log("Duplicate Frequencyid found in the array.");
      setShiftNameError("Same Shift Name Not Allowed");
      setShowLoader(false);
      return;
    } else {
      console.log("No duplicate Frequencyid in the array.");
      setShiftNameError("");
    }

    let isresponse = 0;
    // Loop through each selected day
    for (const day of fullDayNames) {
      const formattedShifts = (shiftState[locId] || []).map((shift) => ({
        Key: shift.id,
        Day: day, // Use the full day name
        Shifttime: formatShiftTime(shift.shiftTime),
        Location: loc_name,
        EmpCount: shift.empCount,
        Frequencyid: shift.Frequencyid,
      }));

      // Find existing schedule for this location
      let res = loc.filter((l) => l.location === loc_name);
      let sch_id = null;
      if (res.length > 0) {
        sch_id = res[0].sch_id;
      }

      // Prepare the request body
      let bodyFormData = {
        weekday: day, // Set the current day from the loop
        sch_id: sch_id,
        is_active: isDefaultTemplate,
        working_operation: `${hoursOfOperation.start}-${hoursOfOperation.end}`,
        shifts: formattedShifts,
        template_name: templatename,
      };

      // Log the data for debugging
      console.log(`Saving shifts for ${day}:`, bodyFormData);

      // Make the API request for each day
      try {
        const response = await makePostRequest(
          "schedular/insertadminschedular",
          bodyFormData
        );
        if (response.data.data) {
          // Reload location list or handle success

          isresponse = 1;
        } else {
          isresponse = 0;
          setLoc([]);
        }
      } catch (error) {
        isresponse = 0;
        console.error(`Error saving shifts for ${day}:`, error);
      }
    }
    if (isresponse == 1) {
      toast.success("Shift time is set successfully!", {
        position: "top-center", // Set the position here
        autoClose: 1500,
      });
      locationList(selectedDay);
      setShowLoader(false);
      setShiftState({});
    }
  };

  const incrementCount = (locId, index) => {
    const newShifts = [...(shiftState[locId] || [])];
    newShifts[index].empCount += 1;
    setShiftState({
      ...shiftState,
      [locId]: newShifts,
    });
  };

  const decrementCount = (locId, index) => {
    const newShifts = [...(shiftState[locId] || [])];
    if (newShifts[index].empCount > 0) {
      newShifts[index].empCount -= 1;
    }
    setShiftState({
      ...shiftState,
      [locId]: newShifts,
    });
  };

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const [totalempcount, setTotalEmpCount] = React.useState(0);
  async function getAllEmployeeList() {
    await makeGetRequest(
      "employee/employeesearch/" +
        localStorage.getItem("companyId") +
        "/created_at/desc/null/0/1000"
    )
      .then((response) => {
        if (response.data.data.res) {
          setTotalEmpCount(response.data.data.res.length);
        }
      })
      .catch((err) => {
        // setShowLoader(false);
      });
  }

  const [initialTemplateCount, setInitialTemplateCount] = React.useState(0);
  async function getTemplateListCount() {
    await makeGetRequest("schedular/getTemplateListCount")
      .then((response) => {
        if (response.data) {
          console.log(response.data);
          setInitialTemplateCount(response.data.data);
          if (response.data.data == 0) {
            setIsDefaultTemplate(true);
          }
        }
      })
      .catch((err) => {});
  }

  const [schema,setSchema]=React.useState('public');
  useEffect(() => {
    localStorage.setItem("TabName", "Schedule");
    const schema=sessionStorage.getItem('schema');
    setSchema(schema);
    const { data } = location.state || {};
    if (data) setIsDefaultTemplate(data.is_active);

    locationList(selectedDay);
    // getAllFrequancyList();
    getAllEmployeeList(null);
    getTemplateListCount();
  }, []);

  const handleDayChangeFromSlider = (nextIndex) => {
    const daysShort = ["M", "T", "W", "Th", "F", "S", "Su"];
    const selectedDay = daysShort[nextIndex];

    // Reset all days to false, then set the selected day to true
    setSelectedDays((prevState) => ({
      ...Object.keys(prevState).reduce(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      ),
      [selectedDay]: true,
    }));

    // Optionally, if you need to handle day-specific functionality:
    handleDayChange(dayMapping[selectedDay]); // if handleDayChange is used for reloading location listing
    getAllFrequancyList(locname, "", selectedDay);
  };

  const settings = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 7,
    speed: 500,
    beforeChange: (current, next) => handleDayChangeFromSlider(next),
  };

  const [expanded, setExpanded] = useState(null);

  // Set the first location as expanded by default when loc is updated
  useEffect(() => {
    if (loc.length > 0) {
      setExpanded(loc[0].location);
      getAllFrequancyList(loc[0].location, "", selectedDay);
    }
  }, [loc]);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   alert("expand",panel);
  //   setExpanded(isExpanded ? panel : null);
  // };

  // The handleChange function
  const handleChange = (location, event, isExpanded) => {
    console.log("Location:", location); // This will log the location correctly
    getAllFrequancyList(location, "", selectedDay);
    setExpanded(isExpanded ? location : null);
  };

  return (
    <>
      <div className="wrapper innerPagesMain">
        <div className="forUpperBottomBt">
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow">
              <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
            </div>
            <h1>
              {" "}
              <marquee>Create New Weekly Schedule Template</marquee>
            </h1>
            <div className="forBackArrow"></div>
          </div>

          <div className="addAdminEmployeeMain">
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={12}>
                <p className="hrsOperation">
                  Hours of Operation <b>{hoursOfOperation.start}</b>
                  <ArrowForwardIcon />
                  <b>{hoursOfOperation.end}</b>
                </p>
              </Grid> */}
              <Grid item xs={12} md={12}>
                <label className="myLable">Enter Shift Schedule Name</label>
                <input
                  input="text"
                  className="myInput"
                  placeholder="Enter Shift Schedule Name"
                  value={templatename}
                  onChange={(e) => setTemplateName(e.target.value)}
                  onBlur={(e) => handleEditTemplate(e)}
                  required
                />
                {templatename == "" && (
                  <div>
                    <p className="errorSt">Please Enter Template Name</p>
                  </div>
                )}
              </Grid>
              {templatename !== "" ? (
                <>
                  <Grid item xs={12} md={12}>
                    <div className="schedulAcordianTpRight">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isDefaultTemplate} // Bind state to the switch
                            onChange={() => {
                              if (initialTemplateCount != 0) {
                                handleSwitchChange();
                              } else {
                                console.error(
                                  "No templates available to switch."
                                );
                              }
                            }} // Handle change
                            disabled={initialTemplateCount == 0}
                          />
                        }
                        label="Apply this as default template"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="withPlsBt">
                      <label className="myLable">Add New Locations</label>
                      <div className="withPlsBtInr">
                        <input
                          type="text"
                          className="myInput"
                          placeholder="Enter Location Name"
                          value={locname}
                          onChange={(e) => setLocationName(e.target.value)}
                        />
                        <Button
                          className="plsB"
                          disabled={locname == "" ? true : false}
                          onClick={addLocation}
                        >
                          <AddIcon />
                        </Button>
                      </div>
                      {locname == "" && loc.length == 0 && (
                        <p className="errorSt">Please Enter Location</p>
                      )}
                    </div>
                  </Grid>
                  {loc.length > 0 && (
                    <Grid item xs={12} md={12}>
                      <div className="mySlik createScheduSlik widthFixedSlide">
                        <div className="slider-container">
                          <Slider {...settings}>
                            {[
                              "Mon",
                              "Tue",
                              "Wed",
                              "Thu",
                              "Fri",
                              "Sat",
                              "Sun",
                            ].map((day) => (
                              <div key={day}>
                                <h3>{day}</h3>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <label className="myLable">Existing Locations</label>
                    {loc.length > 0 && (
                      <div className="schedularAccordianMain">
                        {loc.map((locobj, index) => (
                          <Accordion
                            key={locobj.location}
                            expanded={expanded === locobj.location}
                            onChange={(event, isExpanded) =>
                              handleChange(locobj.location, event, isExpanded)
                            } // Correctly passing location, event, and expanded state
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`${locobj.location}-content`}
                              id={`${locobj.location}-header`}
                            >
                              <Typography>
                                <div className="accordianDelete">
                                  <img
                                    src={acordinDeleteIm}
                                    onClick={() =>
                                      deleteTemplateLocation(locobj.location)
                                    }
                                  />
                                  {locobj.location}{" "}
                                  <span>{locobj.shift_count} Shifts</span>
                                </div>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div className="afterAddShiftDiv">
                                  {locobj.shifts.length > 0 &&
                                    locobj.shifts.map((shiftsobj, index) => (
                                      <div
                                        key={shiftsobj.shift_timing}
                                        className="afterAddShiftInr newAddedTempTop"
                                      >
                                        <em>{shiftsobj.freq_name}</em>
                                        <div className="newAddedTempTopInr">
                                          {/* <em>Shift{index + 1}</em> */}
                                          <p>{shiftsobj.shift_timing}</p>
                                          <span>{shiftsobj.shift_hours}Hr</span>
                                          <div className="actionAftrSc">
                                            <IconButton
                                              aria-label="edit"
                                              className="usrsB"
                                            >
                                              <img src={users} />{" "}
                                              {shiftsobj.emp_count}
                                            </IconButton>
                                            <IconButton
                                              aria-label="edit"
                                              onClick={() =>
                                                handleClickOpen(
                                                  shiftsobj,
                                                  locobj
                                                )
                                              }
                                            >
                                              <img src={edit} />
                                            </IconButton>
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() =>
                                                deleteShift(
                                                  locobj.sch_id,
                                                  shiftsobj.Key
                                                )
                                              }
                                            >
                                              <img src={deleteIm} />
                                            </IconButton>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>

                                <details className="expandTx">
                                  <summary>Add New Shift</summary>

                                  <div className="scheduCon">
                                    {(shiftState[locobj.location] || []).map(
                                      (shift, index) => (
                                        <>
                                          <div
                                            key={shift.id}
                                            className="timingAddDiv"
                                          >
                                            <div className="timingAddLeft">
                                              <label>Shift Hours</label>
                                              <TimeRangePicker
                                                format="hh:mm aa"
                                                showMeridiem
                                                value={shift.shiftTime}
                                                onChange={(time) => {
                                                  // Check if `time` is null (when the X icon is clicked)
                                                  if (!time) {
                                                    const newShifts = [
                                                      ...(shiftState[
                                                        locobj.location
                                                      ] || []),
                                                    ];
                                                    newShifts[index].shiftTime =
                                                      []; // Or reset to your desired default value
                                                    setShiftState({
                                                      ...shiftState,
                                                      [locobj.location]:
                                                        newShifts,
                                                    });
                                                    return;
                                                  }

                                                  const newShifts = [
                                                    ...(shiftState[
                                                      locobj.location
                                                    ] || []),
                                                  ];
                                                  newShifts[index].shiftTime =
                                                    time;
                                                  setShiftState({
                                                    ...shiftState,
                                                    [locobj.location]:
                                                      newShifts,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div className="timingAddLeft">
                                              <label>Emp Per Shift</label>
                                              <div className="countD">
                                                <button
                                                  onClick={() =>
                                                    decrementCount(
                                                      locobj.location,
                                                      index
                                                    )
                                                  }
                                                >
                                                  -
                                                </button>
                                                <div>{shift.empCount}</div>
                                                <button
                                                  onClick={() => {
                                                    totalempcount >
                                                      shift.empCount &&
                                                      incrementCount(
                                                        locobj.location,
                                                        index
                                                      );
                                                  }}
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="creatNewSchedDropMenu kkkkk">
                                            <div className="timingAddLeft">
                                              <label>Assign Shift Name</label>
                                              <select
                                                onChange={(e) =>
                                                  handleChangeShiftLabel(
                                                    e,
                                                    locobj.location,
                                                    index
                                                  )
                                                }
                                                value={shift.Frequencyid}
                                              >
                                                <option value="">
                                                  Select Shift Name
                                                </option>
                                                {freqList.map((item) => (
                                                  <option
                                                    key={item.frequancyid}
                                                    value={item.frequancyid}
                                                  >
                                                    {item.frequancy_name}
                                                  </option>
                                                ))}
                                              </select>
                                              {/* <div>
                                                <p className="errorSt">
                                                  {shiftnameerror}
                                                </p>
                                              </div> */}
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                    <div>
                                      <p className="errorSt extraErrorCss">
                                        {shiftnameerror}
                                      </p>
                                    </div>
                                    <div className="timingAddLeft">
                                      <label>
                                        Select days to apply this shift
                                      </label>
                                      <div className="setDayMain">
                                        <div
                                          className={`dayInD ${
                                            selectedDays.All ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("All")
                                          }
                                        >
                                          All
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.M ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("M")
                                          }
                                        >
                                          M
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.T ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("T")
                                          }
                                        >
                                          T
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.W ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("W")
                                          }
                                        >
                                          W
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.Th ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("Th")
                                          }
                                        >
                                          T
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.F ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("F")
                                          }
                                        >
                                          F
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.S ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("S")
                                          }
                                        >
                                          S
                                        </div>
                                        <div
                                          className={`dayInD ${
                                            selectedDays.Su ? "activDs" : ""
                                          }`}
                                          onClick={() =>
                                            toggleDaySelection("Su")
                                          }
                                        >
                                          Su
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div className="twoBtLi">
                                      <Button
                                        className="addMorScheTimBt saveShiftB"
                                        onClick={() =>
                                          saveShift(
                                            locobj.location,
                                            locobj.location
                                          )
                                        }
                                        disabled={isDisabled}
                                      >
                                        Save Shifts
                                      </Button>
                                      <Button
                                        className="addMorScheTimBt addMorBt"
                                        onClick={() =>
                                          addMoreShift(locobj.location)
                                        }
                                      >
                                        Add More
                                      </Button>
                                    </div>
                                  </div>
                                </details>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    )}
                  </Grid>
                </>
              ) : (
                <p></p>
              )}
            </Grid>
          </div>
        </div>
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Edit Shift</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="schecdulEditMain">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Shift Hours </label>
                        <TimeRangePicker
                          format="hh:mm aa"
                          showMeridiem
                          value={[startTimeedit, endTimeedit]}
                          onChange={handleTimeChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Employees per shift</label>
                        <div className="countD newConInModal">
                          <button onClick={decrementCount2}>-</button>
                          <div>{editshift.emp_count}</div>
                          <button
                            onClick={() => {
                              if (totalempcount > editshift.emp_count) {
                                incrementCount2();
                              }
                            }}
                          >
                            +
                          </button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <label className="myLable">Change Shift Name</label>
                        <div className="  kkkkk">
                          <select
                            className="myInput"
                            value={editFreqid}
                            onChange={handleChangeShiftLabelEdit}
                          >
                            <option value="">Select Shift Name</option>
                            {freqList.map((item) => (
                              <option
                                key={item.frequancyid}
                                value={item.frequancyid}
                              >
                                {item.frequancy_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          className="modBt"
                          onClick={() => updateShift(editshift)}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CreateNewSchedule;
